import { Image } from "antd-mobile";
import emptyImage from "@/assets/empty.png";

export function Empty() {
    return <div className="empty">
        <Image className="empty-image" src={ emptyImage }></Image>
        <div className="empty-text">All Empty</div>
    </div>
}
export default Empty
