import Navbar from "@/components/Navbar";
import { useMatch } from "react-router-dom";
import { useAppSelector } from "@/store";
import { getVmSize, sendBackMessage } from "@/utils";

function HomeHeader() {
    const totalUnread = useAppSelector(state => state.home.totalUnread);
    const setting = useAppSelector(state => state.home.setting);
    const homeRouter = useMatch("/home");

    return <div className="layout-header">
        <Navbar
            badge={ homeRouter ? totalUnread : 0 }
            title="Chats"
            showBack={ !homeRouter }
            onBack={ sendBackMessage }></Navbar>
        <div className="line" style={ { height: getVmSize(8), background: setting ? "#F6F5F3" : "#fff" } }></div>
    </div>;
}

export default HomeHeader;
