import { FloatingPanel, FloatingPanelRef, Mask } from "antd-mobile";
import React, { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import closeIcon from "@/assets/svg/close-modal.svg";
import classNames from "classnames";
// 约定，底部的handler操作，不要写自适应
type FloatModalProps = {
    title?: ReactElement | string;
    handler?: ReactElement;
    children?: ReactNode;
    contentClass?: string;
    visible: boolean;
    onClose?: (val: boolean) => void
}
const config = {
    headerHeight: 28,
    titleHeight: 58,
    handlerHeight: 64,
    anchors: [0, 524, window.innerHeight]
};
const getContentHeight = (height: number, title = false, handler = false) => {
    const { handlerHeight, titleHeight, headerHeight } = config;
    let contentHeight = height - headerHeight;
    if (title) {
        contentHeight -= titleHeight;
    }
    if (handler) {
        contentHeight -= handlerHeight;
    }
    return contentHeight;
};

function FloatModal(props: FloatModalProps) {
    const { visible, title, handler, onClose, children, contentClass } = props;
    const floatRef = useRef<FloatingPanelRef>(null);
    const [contentHeight, setContentHeight] = useState(0);
    // 动态控制内容高度
    const onHeightChange = (height: number, animating: boolean) => {
        const contentHeight = getContentHeight(height, !!title, !!handler);
        setContentHeight(contentHeight);
        if (height === 0 && !animating) {
            onClose?.(false);
        }
    };
    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                floatRef.current?.setHeight(config.anchors[1], { immediate: false });
                setContentHeight(getContentHeight(config.anchors[1]));
            }, 30);
        } else {
            floatRef.current?.setHeight(0);
        }
    }, [visible]);
    return <Mask
        visible={ visible }
        className="my-floatModal"
        onMaskClick={ () => onClose?.(false) }
        getContainer={ () => document.body }
        opacity={ 0.37 }
        destroyOnClose={ true }
    >
        <FloatingPanel
            ref={ floatRef }
            className={ classNames("my-floatModal-panel") }
            onHeightChange={ onHeightChange }
            anchors={ config.anchors }
            // handleDraggingOfContent={ false }
        >
            {
                title ? <div className="floatModal-header">
                    <img src={ closeIcon } alt="" className="close" onClick={ () => onClose?.(false) }/>
                    <div className="title">{ title }</div>
                </div> : null
            }

            <div className={ classNames("floatModal-content", contentClass) }
                 style={ { height: contentHeight + "px" } }>
                {
                    children
                }
            </div>
            {
                handler ? <div className="floatModal-handler">
                    { handler }
                </div> : null
            }
        </FloatingPanel>
    </Mask>;
}

export default FloatModal;
