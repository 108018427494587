const faceEmoji = [
    [128512, 128580],
    [129296, 129327],
];
const getFaceList = () => {
    const faceList = [];
    for (let i = 0; i < faceEmoji.length; i++) {
        const [start, end] = faceEmoji[i];
        for (let j = start; j <= end; j++) {
            faceList.push({
                context: String.fromCodePoint(j),
                id: j,
            });
        }
    }
    return faceList;
};
export const EmojiDataList = [
    ...getFaceList()
];
