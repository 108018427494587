import { useAppSelector } from "@/store";
import { Image } from "antd-mobile";
import { ExpressType } from "@/pages/Chat/feature/InputFoot/InputExpression";

type GifBoxProps = {
    chooseItem: (type: ExpressType, url: string) => void;
}
export default function GifBox(props: GifBoxProps) {
    const gifList = useAppSelector(state => state.chat.gifImages);
    const { chooseItem } = props;
    return <div className="chat-expression-gif">
        <wc-waterfall cols={ 3 } gap={ 4 }>
            {
                gifList.map(item => {
                    return <Image onClick={ () => chooseItem("image", item.url) }
                                  className="gif-item"
                                  src={ item.url }
                                  key={ item.id } alt=""/>;
                })
            }
        </wc-waterfall>

    </div>;
}
