import { useEffect } from "react";
import FavoriteGames from "./feature/FavoriteGames";
import Statistics from "./feature/Statistics";
import User from "./feature/User";
import Header from "./feature/Header";
import "./personal.scss";
import { useAppDispatch, useAppSelector } from "@/store";
import { getUserByGroupId, reset } from "@/store/slice/personal";
import AvatarModal from "./feature/AvatarModal";
import { Popup } from "antd-mobile";
import MuteModal from "@/pages/Chat/feature/Modal/PersonalModal/feature/MuteModal";
import CancelMuteModal from "@/pages/Chat/feature/Modal/PersonalModal/feature/CancelMuteModal";

function Personal() {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.personal);
    const personVisible = useAppSelector(state => state.personal.personVisible);
    useEffect(() => {
        if (personVisible) {
            dispatch(getUserByGroupId());
        } else {
            dispatch(reset());
        }
        return () => {
        };
    }, [personVisible]);
    return <Popup visible={ personVisible } position="right" destroyOnClose>
        <div className="personalPage" style={ { backgroundImage: `url(${ user.faceURL })` } }>
            <div className="float-contain">
                <Header></Header>
                <User></User>
                <Statistics/>
                <FavoriteGames></FavoriteGames>
            </div>
            <AvatarModal/>
            <MuteModal/>
            <CancelMuteModal/>
        </div>
    </Popup>;
}

export default Personal;
