import React, { useContext, useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";
import dropIcon from "@/assets/svg/rubbish-voice.svg";
import pauseIcon from "@/assets/svg/pause.svg";
import sendIcon from "@/assets/svg/fly.svg";
import recodIcon from "@/assets/svg/voice-red.svg";
import { ImContext } from "@/openIm";
import { uid } from "uid";
import { addNewMessage, setQuoteMessage, updateMessage } from "@/store/slice/chat";
import { useAppDispatch, useAppSelector } from "@/store";
import { resetVoice, setTime, setVoiceBoxVisible } from "@/store/slice/voice";
import { emitter } from "@/utils";
import { useLatest } from "ahooks";
import config from "@/config";
import { hideLoading, showLoading } from "@/store/slice/common";

let scrollingWaveform = true;

function VoiceBox() {
    const { openIm } = useContext(ImContext);
    const closeStatus = useRef<"send" | "close">();
    const dispatch = useAppDispatch();
    const { chatInfo } = useAppSelector(state => state.chat);
    const { duration: storeDuration, formatTime } = useAppSelector(state => state.voice);
    const duration = useLatest(storeDuration);
    // 两个状态，一个录制状态一个暂停状态
    const [isRecording, setRecording] = useState(false);
    const waveRef = useRef<HTMLDivElement>(null);
    const waveRecord = useRef<any>();
    const start = () => {
        const deviceId = "default";
        waveRecord.current.startRecording({ deviceId }).then(() => {
            setRecording(true);
        });
    };
    const handlePaly = () => {
        setRecording(!isRecording);
        if (isRecording) {
            // 暂停
            waveRecord.current.pauseRecording();
        } else {
            waveRecord.current.resumeRecording();
        }
    };
    const sendVoice = () => {
        if (!waveRecord.current) {
            return;
        }
        // 结束录制
        closeStatus.current = "send";
        waveRecord.current.stopRecording();
        // dispatch(setShowVoice(false));
    };
    const close = (send = false) => {
        closeStatus.current = send ? "send" : "close";
        waveRecord.current.destroy();
    };
    // 初始化
    const initRecord = () => {
        const wavesurfer = WaveSurfer.create({
            ...config.wave,
            container: waveRef.current as HTMLElement,
            cursorWidth: 0,
            height: 40,
        });
        // 更新本地音频设备
        const record = wavesurfer.registerPlugin(RecordPlugin.create({
            scrollingWaveform,
            renderRecordedAudio: false
        }));
        record.on("record-end", async function (blob: any) {
            // openIm.createVoiceMessage(blob)
            const file = new File([blob], uid() + Date.now() + "." + blob.type, { type: blob.type });
            if (closeStatus.current === "send") {
                dispatch(showLoading());
                const message = await openIm.createVoiceMessage(file, duration.current);
                dispatch(addNewMessage(message));
                dispatch(hideLoading());
                Promise.resolve().then(() => {
                    emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "smooth" });
                });
                const res = await openIm.sendMessage({
                    message,
                    recvID: chatInfo.userID,
                    groupID: chatInfo.groupID
                });
                // 更新信息
                dispatch(updateMessage(res));
                // 清空引用
                dispatch(setQuoteMessage(null));
            }
            dispatch(resetVoice());
        });
        record.on('record-progress', (time: number) => {
            // updateProgress(time);
            dispatch(setTime(time));
        });
        waveRecord.current = record;
    };
    useEffect(() => {
        initRecord();
        start();
        return () => {
            close();
        };
    }, []);
    return <div className="voice-box">
        <div className="voice-box-show">
            <div className="progress">{ formatTime }</div>
            {/*voice-wave*/ }
            <div className="wave" id="voice-wave" ref={ waveRef }></div>
        </div>
        <div className="voice-box-handler">

            <div className="handler-item" onClick={ () => dispatch(setVoiceBoxVisible(false)) }>
                <img src={ dropIcon } alt="" className="icon"/>
            </div>
            <div className="handler-item" onClick={ handlePaly }>
                <img src={ isRecording ? pauseIcon : recodIcon } alt="" className="icon"/>
            </div>
            <div className="handler-item sendBtn" onClick={ sendVoice }>
                <img src={ sendIcon } alt="" className="icon"/>
            </div>
        </div>
    </div>;
}

export default VoiceBox;
