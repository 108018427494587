import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import weekday from "dayjs/plugin/weekday";
import isToday from "dayjs/plugin/isToday";
import duration from "dayjs/plugin/duration";
import _ from "lodash";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";
import { uid } from "uid";

dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(weekday);
export const addZero = (num: number | string, len = 2) => {
    return _.padStart(num + "", len, "0");
};
export const formatConversionTime = (timestemp: number): string => {
    if (!timestemp) return "";
    // const fromNowStr = dayjs(timestemp).fromNow();
    // if (fromNowStr.includes("year")) {
    //     return dayjs(timestemp).format("YY/MM/DD hh:mma")
    // }
    return formatMessageTime(timestemp);
};

export const formatMessageTime = (timestemp: number): string => {
    if (!timestemp) return "";
    const keepDay = dayjs(timestemp).isToday();
    if (keepDay) {
        return dayjs(timestemp).format("hh:mma");
    }
    return dayjs(timestemp).format("YY/MM/DD hh:mma");
};
// 两个时间是否大于设定的分钟数
export const isShowTime = (prev: number, cur: number, maxValue = 5) => {
    if (!prev) return false;
    const diffMinute = dayjs(cur).diff(prev, "minute");
    return diffMinute > maxValue;
};

export const parseStr = (jsonStr: string) => {
    return JSON.parse(jsonStr || "{}");
};

// 获取文件file对象
export const getFileByUrl = (url: string): Promise<File> => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
            if (this.status === 200) {
                const blob = this.response;
                const file = new File([blob], uid(), { type: blob.type });
                resolve(file);
            }
        };
        xhr.onerror = function () {
            reject(new Error("Error fetching file"));
        };
        xhr.send();
    });
};

// 获取图片的信息
export const getImageInfo = (url: string): Promise<{ width: number; height: number, url: string }> => {
    // const _URL = window.URL || window.webkitURL;
    return new Promise((resolve, reject) => {
        const img = new Image();
        // const url = _URL.createObjectURL(file);
        img.src = url;
        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height,
                url
            });
        };
        img.onerror = () => {
            reject(new Error("Error fetching image"));
        };
    });
};

// 根据时间戳获取剩余时间
export const formattedTime = (time: number) => {
    const list = [
        Math.floor((time % (60 * 60 * 1000)) / 60000), // minutes
        Math.floor((time % (60 * 1000)) / 1000), // seconds
    ]
        .map((v) => (v < 10 ? '0' + v : v));
    return list;
};

// 获取音频列表
export const getAudioDevicesList = async () => {
    try {
        const devices = await RecordPlugin.getAvailableAudioDevices();
        const list = devices.map((item: any) => {
            return {
                label: item.label || item.deviceId,
                value: item.deviceId
            };
        });
        return list;
    } catch (err) {
        return [];
    }

};

// 转换金额为k
export const formatMoney = (money: number) => {
    if (money < 1000) {
        return money;
    }
    return `${ (money / 1000).toFixed(2) }k`;
};
