import React, { useEffect, useRef, useState } from 'react';
import { Input } from "antd-mobile";
import "./passwordRoom.scss";

const PasswordRoom = () => {
    const codeInput = useRef<any>(null);
    const [value, setValue] = useState("");
    const handleChange = (value: string) => {
        if (value.length > 4) {
            return;
        }
        setValue(value);
    };
    const focus = () => {
        const inputDom = codeInput.current;
        if (inputDom) {
            inputDom.focus();
        }
    };
    useEffect(() => {
        setTimeout(focus, 1000)
    }, []);
    return (
        <div>
            <div className="password-room">
                <div className="title">password</div>
                <div className="password-input" onClick={ focus }>
                    <div className="input-item">{ value[0] }</div>
                    <div className="input-item">{ value[1] }</div>
                    <div className="input-item">{ value[2] }</div>
                    <div className="input-item">{ value[3] }</div>
                </div>
                <div className="password-rule">
                    <p>VIP1 rule：XXXXXXXXXXXXX</p>
                    <p>contact the customer service</p>
                </div>
            </div>
            <Input className="code-input"
                   value={ value }
                   type="number"
                   max={ 4 }
                   onChange={ handleChange }
                   ref={ codeInput }
            />
        </div>

    );
};

export default PasswordRoom;
