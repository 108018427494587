import { memo } from "react";

type ReflexSattaProps = {
    type: string;
    value: string;
    flexDirection?: "row" | "column";
}

function ReflexSatta(props: ReflexSattaProps) {
    const { flexDirection = "row" } = props;
    const style = {
        alignItems: flexDirection === "row" ? "center" : "start",
        flexDirection
    };
    return <div className="reflex-container">
        <div className="satta" style={ style }>
            <div className="satta-item"> { props.type } </div>
            <div className="value">{ props.value } </div>
        </div>
    </div>;
}

export default memo(ReflexSatta);
