import { formatMessageTime } from "@/utils/utils";
import React from "react";
import { MessageItem } from "open-im-sdk/lib/types/entity";
import messageSendingIcon from "@/assets/svg/message-sending.svg";
import { MessageStatus } from "open-im-sdk";

type MessageStateProps = {
    data: MessageItem;
    style?: React.CSSProperties
}

function MessageState(props: MessageStateProps) {
    const { data, style = {} } = props;
    return <div className="message-state" style={style}>
        <span>{ formatMessageTime(data.sendTime) }</span>
        {
            data.status === MessageStatus.Sending && <img src={ messageSendingIcon } alt=""/>
        }
    </div>
}

export default MessageState;
