import { useContext, useEffect } from "react";
import { getGifImage } from "@/apis/im";
import { useAppDispatch } from "@/store";
import { setAdminList, setGifImages, setSelfInfo } from "@/store/slice/chat";
import ImContext from "@/openIm/ImContext";
import { useQueryString } from "@/hooks/useQueryString";

function useInit() {
    const dispatch = useAppDispatch();
    const [groupID, userID] = useQueryString(["groupID", "userID"]);
    const { openIm } = useContext(ImContext);
    // 获取gif
    const getGif = async () => {
        const res = await getGifImage({ pageNumber: 1, showNumber: 100 });
        if (res.status === 0) {
            dispatch(setGifImages(res.data.gifs));
        }
    };
    // 获取自己在群里的个人资料
    const getSelfInfo = async () => {
        try {
            const res = await openIm.getGroupMemberInfo(groupID, userID);
            dispatch(setSelfInfo(res));
        } catch (err) {
            console.error(err);
        }
    };
    // 获取当前群的管理列表
    const getMemberList = async () => {
        const members = await openIm.getGroupMemberOwnerAndAdmin(groupID);
        dispatch(setAdminList(members));
    };
    useEffect(() => {
        getGif();
        getSelfInfo();
        getMemberList();
    }, []);
}

export default useInit;
