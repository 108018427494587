import store2 from "store2";
import store from "@/store";
import { setEditorModalVisible, setLoginModalVisible } from "@/store/slice/common";

export function isVisitorUser() {
    return store2.get("isVisitor");
}

export function authLogin() {
    if (isVisitorUser()) {
        store.dispatch(setLoginModalVisible(true));
        return false;
    }
    return true;
}

// 判断当前客户是否是默认的客户，如果是的话，需要跳转登录
export function isDefaultUser() {
    const defaultUserName = [
        "user",
        "username"
    ];
    const data = store.getState();
    const user = data.common.user;
    if (defaultUserName.includes(user.nickname)) {
        store.dispatch(setEditorModalVisible(true));
        return true;
    }
    return false;

}
