import { useAppSelector } from "@/store";
import { useEffect, useRef, useState } from "react";
import type { GroupMemberItem } from "open-im-sdk";

// 获取登录用户在群里的信息
export function useCurrentUser() {
    const groupMemberList = useAppSelector(state => state.chat.groupOwnerList);
    const userId = useAppSelector(state => state.chat.chatInfo.userID);
    const [currentUser, setCurrentUser] = useState({} as GroupMemberItem);
    // 是否禁言
    const [muted, setMuted] = useState(false);
    const timer = useRef<NodeJS.Timeout>();
    useEffect(() => {
        const member = groupMemberList.find(item => item.userID === userId);
        if (member) {
            timer.current = setInterval(() => {
                setMuted(member.muteEndTime - Date.now() > 0);
            }, 1000);
            setCurrentUser(member);
        }
        return () => {
            timer.current && clearInterval(timer.current);
        };
    }, [groupMemberList, userId]);
    return {
        currentUser,
        muted
    };
}
