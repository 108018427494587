import { useMatch } from "react-router-dom";
import HomeHeader from "@/layout/Header/HomeHeader";
import ChatHeader from "@/layout/Header/ChatHeader";

function Header() {
    const homeRouter = useMatch("/home");
    let headerHtml = () => homeRouter ? <HomeHeader></HomeHeader> : <ChatHeader/>;

    return <>
        {
            headerHtml()
        }
    </>;
}

export default Header;
