const config = new Map(
    [
        [1, "https://lt-x-api.lotteryanna.com/app"],
        [2, "https://api.bhaulotterys.com/app"],
        [3, "https://api.supergame123.xyz/app"],
        [5, "https://api.99lotterys.com/app"],
    ]
);

export default config;
