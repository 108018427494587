import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IMSDK } from "@/openIm";
import type { ConversationItem } from "open-im-sdk/lib/types/entity";

const initialState = {
    list: [] as ConversationItem[],
    loading: true,
    setting: false,
    selectList: [] as ConversationItem[],
    totalUnread: 0,
    goChatFirst: false,
    oneChat: {
        conversationID: "",
        groupID: ""
    }
};


// 异步action。 获取历史会话记录
export const fetchConversationList = createAsyncThunk(
    "home/conversationList",
    async () => {
        const res = await IMSDK.getConversationListSplit({
            offset: 0,
            count: 200
        });
        return res.data || [];
    }
);
// 获取所有的未读数
export const fetchTotalUnread = createAsyncThunk(
    "home/totalUnread",
    async (_, { dispatch }) => {
        const res = await IMSDK.getTotalUnreadMsgCount();
        dispatch(setTotalUnread(res.data));
        return res.data;
    }
);
// 首页会话相关
export const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        setConversationList(state, action) {
            state.list = action.payload;
        },
        setTotalUnread(state, action) {
            state.totalUnread = action.payload;
        },
        // 更新单条会话数据
        updateConversationList(state, action) {
            const currentIndex = state.list.findIndex(item => item.conversationID === action.payload.conversationID);
            if (~currentIndex) {
                state.list[currentIndex] = action.payload;
            }
        },
        // 新增一个会话记录
        addConversationList(state, action) {
            let data = action.payload;
            if (!Array.isArray(data)) {
                data = [data];
            }
            const addList = data.filter((newItem: any) => {
                const curIndex = state.list.findIndex(item => newItem.conversationID === item.conversationID);
                return !~curIndex;

            });
            state.list.push(...addList);
        },
        showSetting(state) {
            state.setting = true;
        },
        hideSetting(state) {
            state.setting = false;
        },
        addSelectItem(state, action) {
            const curIndex = state.selectList.findIndex(item => item.conversationID === action.payload.conversationID);
            if (~curIndex) {
                state.selectList.splice(curIndex, 1);
                if (state.selectList.length === 0) {
                    state.setting = false;
                }
            } else {
                state.selectList.push(action.payload);
            }
        },
        resetSetting(state) {
            state.setting = false;
            state.selectList = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchConversationList.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchConversationList.fulfilled, (state, action) => {
            state.list = action.payload;
            state.loading = false;
            // 如果列表为1个 那么直接跳聊天，否则停留
            if (action.payload.length === 1) {
                const conversation = action.payload[0];
                const { conversationID, groupID } = conversation;
                state.oneChat = {
                    conversationID,
                    groupID
                };
            }
        });
        builder.addCase(fetchConversationList.rejected, state => {
            state.loading = false;
        });
    }
});
export const {
    setConversationList,
    setTotalUnread,
    updateConversationList,
    addConversationList,
    showSetting,
    hideSetting,
    resetSetting,
    addSelectItem
} = homeSlice.actions;


export default homeSlice.reducer;
