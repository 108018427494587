import React, { useEffect, useRef, useState } from "react";
import { MessageItem } from "open-im-sdk/lib/types/entity";
import startPlayIcon from "@/assets/svg/startPlay.svg";
import stopPlayIcon from "@/assets/svg/stopPlay.svg";
import voiceAvatarIcon from "@/assets/svg/voice-avatar.svg";
import WaveSurfer from "wavesurfer.js";
import { formattedTime } from "@/utils/utils";
import { useAppDispatch, useAppSelector } from "@/store";
import { setCurrentVoiceId } from "@/store/slice/chat";
import MessageState from "@/pages/Chat/feature/MessageItem/MessageState";
import voiceIcon from "@/assets/svg/voice-vector.svg";
import { Image } from "antd-mobile";
import config from "@/config";

type VoiceMessageProps = {
    data: MessageItem
}
export default function VoiceMessage(props: VoiceMessageProps) {
    const { data } = props;
    const dispatch = useAppDispatch();
    const currentVoiceClientMsgID = useAppSelector(state => state.chat.currentVoiceClientMsgID);
    const waveRef = useRef<any>();
    const [time, setTime] = useState<(number | string)[]>([0, 0]);
    const waveSurferRef = useRef<WaveSurfer>();
    const [isPlay, setIsPlay] = useState(false);
    const handlerPlay = () => {
        dispatch(setCurrentVoiceId(data.clientMsgID));
        setIsPlay(!isPlay);
        waveSurferRef.current?.playPause();
    };
    const initWave = async () => {
        const waveDom = waveRef.current;
        const url = data.soundElem.sourceUrl.replace(/^http:\/\//, "https://");
        const waveSurfer = WaveSurfer.create({
            ...config.wave,
            container: waveDom,
            // url: data.soundElem.sourceUrl,
            url,
            duration: data.soundElem.duration,
            height: waveDom.offsetHeight
        });
        waveSurfer.on("decode", () => {
            const duration = formattedTime(waveSurfer.getDuration() * 1000);
            setTime(duration);
        });
        waveSurfer.on("finish", () => {
            setIsPlay(false);
            waveSurfer.setTime(0);
        });
        waveSurferRef.current = waveSurfer;
    };
    useEffect(() => {
        initWave();
    }, []);
    useEffect(() => {
        if (currentVoiceClientMsgID !== data.clientMsgID) {
            // 关闭音频，防止多个音频出现
            waveSurferRef.current?.pause();
            setIsPlay(false);
        }
    }, [currentVoiceClientMsgID]);
    return <div className="voice-message">
        <div className="voice-message-left">
            <Image fallback={ <Image src={ voiceAvatarIcon }></Image> } placeholder={ <Image src={ voiceAvatarIcon }/> }
                   src={ data.senderFaceUrl } alt=""
                   className="voice-avatar"/>
            <img src={ voiceIcon } className="icon" alt=""/>
        </div>
        <div className="voice-message-content">
            <div className="voice-message-content-handler" onClick={ handlerPlay }>
                <img src={ isPlay ? stopPlayIcon : startPlayIcon } alt="" className="icon"/>
            </div>
            <div className="voice-message-content-right">
                <div className="message-wave voice-wave" ref={ waveRef }></div>
                <div className="detail">
                    <span>{ time.join(":") }</span>
                    <MessageState data={ data }></MessageState>
                </div>
            </div>
        </div>
    </div>;
}
