import { Image } from "antd-mobile";
import React, { TouchEvent, useContext, useRef, useState } from "react";
import classnames from "classnames";
import store from "store2";
import TextMesssage from "@/pages/Chat/feature/MessageItem/TextMesssage";
import QuoteMessage from "@/pages/Chat/feature/MessageItem/quoteMessage";
import { addSelectList, ChatMessageItem, setSelectState, updateMessage } from "@/store/slice/chat";
import { useAppDispatch, useAppSelector } from "@/store";
import { GroupMemberRole, MessageStatus, MessageType } from "open-im-sdk";
import PictureMessage from "@/pages/Chat/feature/MessageItem/PictureMessage";
import VoiceMessage from "@/pages/Chat/feature/MessageItem/voiceMessage";
import messageFaildedIcon from "@/assets/svg/message-failed.svg";
import { ImContext } from "@/openIm";
import ShareCards from "@/pages/Chat/feature/ShareCards";
import { authLogin, getVmSize, replaceOssImage } from "@/utils";
import { openPersonVisible } from "@/store/slice/personal";
import defaultPersonPic from "@/assets/svg/default-person.svg";
import MessageState from "@/pages/Chat/feature/MessageItem/MessageState";
import { renderMemberRole } from "@/pages/Chat/feature/Modal/SettingModal/Member";

type ChatItem = {
    data: ChatMessageItem,
}

function ChatItem(props: ChatItem) {
    const {
        data
    } = props;
    const dispatch = useAppDispatch();
    const { isSelect, selectList, chatInfo, adminList } = useAppSelector(state => state.chat);
    const { openIm } = useContext(ImContext);
    const [resendLoading, setResendLoading] = useState(false);
    const touchTimer = useRef<NodeJS.Timeout>();
    const longPress = useRef(false);
    const chatRowRef = useRef<HTMLDivElement>(null);
    const [touch, setTouch] = useState({
        touchX: 0,
        touchY: 0,
    });
    const isMain = !data.isMergeMessage;
    const self = store.get("userID") === data.sendID;
    const renderAvatar = () => {
        if (self) return null;
        if (!self && isMain) {
            // 替换老的oss图片
            const faceUrl = replaceOssImage(data.senderFaceUrl);
            return <Image
                className="chat-avatar"
                placeholder={ <Image src={ defaultPersonPic }/> }
                fallback={ <Image src={ defaultPersonPic }/> }
                src={ faceUrl }
                onContainerClick={ () => {
                    if (authLogin()) {
                        dispatch(openPersonVisible({ userId: data.sendID, groupId: data.groupID }));
                    }
                } }></Image>;
        }
        return <div className="chat-avatar"></div>;
    };
    const renderNickname = () => {
        let adminData = adminList.find(item => item.userID === data.sendID);
        if (isMain && !self) return <div className="message-nickname">
            <span>
            { data.senderNickname }
            </span>
            {
                adminData && renderMemberRole(adminData.roleLevel, getVmSize(5))
            }
        </div>;
        return null;
    };

    // 判断是否被选中
    const iscurrentSelect = isSelect && selectList.find(item => item.clientMsgID === data.clientMsgID);
    const renderMessageContent = () => {

        // ShareScratchMessage
        switch (data.contentType) {
            case MessageType.TextMessage:
                return <TextMesssage data={ data }></TextMesssage>;
            case MessageType.QuoteMessage:
                return <QuoteMessage data={ data }></QuoteMessage>;
            case MessageType.CustomMessage:
                try {
                    const customData = JSON.parse(data.customElem.data);
                    if (customData.type === "image") {
                        return <PictureMessage data={ data } image={ customData }></PictureMessage>;
                    }
                    return <>
                        <ShareCards self={ self } data={ data }></ShareCards>
                        <MessageState style={ { float: "right", marginTop: getVmSize(4) } } data={ data }/>
                    </>;
                } catch (err) {
                    return <div>{ data.customElem.data }</div>;
                }
            // case MessageType.PictureMessage:
            // return <PictureMessage data={ data }></PictureMessage>;
            case MessageType.VoiceMessage:
                return <VoiceMessage data={ data }></VoiceMessage>;
            default:
                return <div>error Type</div>;
        }

    };
    const handlerTouchStart = (e: TouchEvent<HTMLDivElement>) => {
        // e.preventDefault();
        const touchX = e.targetTouches[0].screenX;
        const touchY = e.targetTouches[0].screenY;
        setTouch({
            touchX,
            touchY
        });
        if (touchTimer.current) {
            clearTimeout(touchTimer.current);
        }
        touchTimer.current = setTimeout(() => {
            dispatch(setSelectState(true));
            dispatch(addSelectList(data));
            longPress.current = true;
        }, 500);
    };
    const handlerTouchMove = (e: TouchEvent<HTMLDivElement>) => {
        const moveX = e.targetTouches[0].screenX;
        const moveY = e.targetTouches[0].screenY;
        // 解决vivo机型，手指没有move，touchmove事件仍然会调用而导致setTimeout被clear
        if (touch.touchX !== moveX || touch.touchX !== moveY) {
            // 手指滑动，清除定时器，中断长按逻辑
            if (touchTimer.current) {
                clearTimeout(touchTimer.current);
            }
        }
    };
    const handlerTouchEnd = () => {
        // 清除定时器，结束长按逻辑
        if (touchTimer.current) {
            clearTimeout(touchTimer.current);
        }
        if (!longPress.current) {
            if (isSelect) {
                // 进入seleted状态
                dispatch(addSelectList(data));
            }

        } else {
            console.log("长按");
        }
        // 若手指离开屏幕，时间小于我们设置的长按时间，则为点击事件
        longPress.current = false;
    };
    // 重发信息
    const resend = async (msg: ChatMessageItem) => {
        if (resendLoading) return;
        setResendLoading(true);
        try {
            const message = {
                ...msg,
                status: MessageStatus.Sending
            };
            dispatch(updateMessage(message));
            const res = await openIm.sendMessage({
                message: msg,
                recvID: chatInfo.userID,
                groupID: chatInfo.groupID
            });
            dispatch(updateMessage(res));
        } catch (err) {
            dispatch(updateMessage({
                ...msg,
                status: MessageStatus.Failed
            }));
        } finally {
            setResendLoading(false);
        }


    };
    return <div
        ref={ chatRowRef }
        className={ classnames("chat-row", { selected: iscurrentSelect, marge: data.isMergeMessage }) }
        onTouchStart={ handlerTouchStart }
        onTouchMove={ handlerTouchMove }
        onTouchEnd={ handlerTouchEnd }
    >
        <div className={ classnames("chat-row-container", { self }) }>
            {
                data.status === MessageStatus.Failed && <div className="message-icon" onClick={ () => resend(data) }>
                    <img alt="" src={ messageFaildedIcon }/>
                </div>
            }
            {
                renderAvatar()
            }
            <div className={ classnames("message", { main: isMain }) }>
                {
                    renderNickname()
                }
                <div className="message-content">
                    {
                        renderMessageContent()
                    }
                </div>
            </div>

        </div>
    </div>;
}

export default ChatItem;
