import FloatModal from "@/components/FloatModal";
import { Image } from "antd-mobile";
import bellIcon from "@/assets/svg/notice-bell.svg";
import React, { memo } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import { setNoticeBarModalVisible } from "@/store/slice/chat";

function NoticeDetailModal() {
    const groupInfo = useAppSelector(state => state.chat.groupInfo);
    const noticeBarModalVisible = useAppSelector(state => state.chat.noticeBarModalVisible);
    const dispatch = useAppDispatch();
    return <FloatModal
        visible={ noticeBarModalVisible }
        title="Mirabel 💖"
        onClose={ () => {
            dispatch(setNoticeBarModalVisible(false));
        } }
    >
        <div className="announcements">
            <div className="bell">
                <Image src={ bellIcon } className="icon"></Image>
            </div>
            <div className="announcements-right">
                <div className="announcements-title">Announcements</div>
                <div className="announcements-inner">
                    { groupInfo?.notification }
                </div>
            </div>
        </div>

    </FloatModal>;
}

export default memo(NoticeDetailModal);
