import type { MessageItem, SelfUserInfo, WsResponse } from "open-im-sdk";
import { CbEvents, MessageReceiveOptType, OpenIMSDK } from "open-im-sdk";
import store2 from "store2";
import store from "@/store";
import { GetOneConversationParams, SendMsgParams } from "open-im-sdk/lib/types/params";
import type { ConversationItem } from "open-im-sdk/lib/types/entity";
import {
    addNewMessage,
    deleteMessageFormStore, notNeedType,
    setSelfInfo,
    updateGroupInfo,
    updateMessageUser
} from "@/store/slice/chat";
import { addConversationList, setTotalUnread, updateConversationList } from "@/store/slice/home";
import { uid } from "uid";
import { getImageInfo } from "@/utils/utils";
import { emitter, toast } from "@/utils";
import { setExpiredVisible, setKickVisible, updateCommonUser } from "@/store/slice/common";

export const IMSDK = new OpenIMSDK();
type OpenImOptions = {
    afterLogin?: (userInfo: SelfUserInfo | null) => void;
    store: typeof store;
    originToken?: string;
}
type LoginTypeParams = {
    originToken: string;
    imToken: string;
    userID: string;
    chatToken: string;
} | null | undefined

class OpenIm {
    IMSDK = IMSDK;
    afterLogin?: (userInfo: SelfUserInfo | null) => void;
    store: any;

    constructor(options: OpenImOptions) {
        this.init(options);
    }

    init(options: OpenImOptions) {
        this.afterLogin = options.afterLogin;
        this.store = options.store;
        this.setIMListener();
        // this.login();
    }

    async login(data: LoginTypeParams) {
        if (!data) {
            this.afterLogin?.(null);
            return;
        }
        const { imToken, userID } = data;
        store2.setAll(data);
        try {
            await this.IMSDK.login({
                userID,       // IM 用户 userID
                token: imToken,        // IM 用户令牌
                platformID: 5,   // 当前登录平台号
                apiAddr: process.env.REACT_APP_IM_API_URL!,   // IM api 地址
                wsAddr: process.env.REACT_APP_IM_WS_URL!   // IM ws 地址
            });
            const userInfo = await this.IMSDK.getSelfUserInfo();
            if (userInfo) {
                this.afterLogin?.(userInfo.data);
            }
        } catch (err) {
            console.error("err", err);
        }

    }

    loginOut() {
        this.IMSDK.logout();
    }

    // 接受信息回调
    newMessageHandler = ({ data }: WsResponse<MessageItem | MessageItem[]>) => {
        const list = Array.isArray(data) ? data : [data];
        const chat = this.store.getState().chat;
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            if (!notNeedType.includes(item.contentType)) {
                // 判断是否是当前群
                if (chat.chatInfo.groupID === item.groupID) {
                    this.store.dispatch(addNewMessage(item));
                }
            }
        }
        Promise.resolve().then(() => {
            // 判断当前是不是在最后
            emitter.emit("CHAT_LIST_SCROLL_BY_SHOW_BTN");
        });
    };
    // 会话总未读数发生变化
    totalUnreadChangeHandler = ({ data }: WsResponse<number>) => {
        this.store.dispatch(setTotalUnread(data));
    };
    // 会话信息发生变化
    conversationChangedHandler = ({ data }: WsResponse<ConversationItem[]>) => {
        data.forEach(item => {
            // 查找所有的会话 更新会话
            this.store.dispatch(updateConversationList(item));
        });
    };
    // 产生新的会话
    newConversationHandler = ({ data }: WsResponse<ConversationItem[]>) => {
        this.store.dispatch(addConversationList(data));
    };

    setIMListener() {
        // account
        // 用户信息发生变动
        IMSDK.on(CbEvents.OnSelfInfoUpdated, ({ data }) => {
            this.store.dispatch(updateCommonUser(data));
        });
        // IMSDK.on(CbEvents.OnConnecting, () => {
        //     console.log("connectiong");
        // });
        // IMSDK.on(CbEvents.OnConnectFailed, () => {
        //     console.log("onconnectfailed");
        // });
        // IMSDK.on(CbEvents.OnConnectSuccess, connectSuccessHandler);

        // sync
        // IMSDK.on(CbEvents.OnSyncServerStart, syncStartHandler);
        // IMSDK.on(CbEvents.OnSyncServerFinish, syncFinishHandler);
        IMSDK.on(CbEvents.OnSyncServerFailed, () => {
            toast("Sync failed!", "error");
        });

        // message
        // IMSDK.on(CbEvents.OnRecvNewMessage, this.newMessageHandler as any);
        IMSDK.on(CbEvents.OnRecvNewMessages, this.newMessageHandler as any);
        IMSDK.on(CbEvents.OnNewRecvMessageRevoked, () => {
            // console.log("撤回");
        });

        // conversation
        // 某些会话关键信息发生变化
        IMSDK.on(CbEvents.OnConversationChanged, this.conversationChangedHandler as any);
        // 新会话产生
        IMSDK.on(CbEvents.OnNewConversation, this.newConversationHandler as any);
        IMSDK.on(
            CbEvents.OnTotalUnreadMessageCountChanged,
            this.totalUnreadChangeHandler as any
        );

        // group
        // IMSDK.on(CbEvents.OnJoinedGroupAdded, ({ data }) => {
        //     console.log(data, "add group");
        // });
        // IMSDK.on(CbEvents.OnJoinedGroupDeleted, ({ data }) => {
        //     console.log("deleted group", data);
        // });
        IMSDK.on(CbEvents.OnGroupInfoChanged, ({ data }: any) => {
            const chat = this.store.getState().chat;
            if (chat.chatInfo.groupID === data.groupID) {
                this.store.dispatch(updateGroupInfo(data));
            }
        });
        // IMSDK.on(CbEvents.OnGroupMemberAdded, groupMemberAddedHandler);
        // IMSDK.on(CbEvents.OnGroupMemberDeleted, groupMemberDeletedHandler);
        // 某成员信息发生变更
        IMSDK.on(CbEvents.OnGroupMemberInfoChanged, ({ data }: any) => {
            // 将当前sendID，循环列表，修改这个群成员的聊天信息
            // 判断是否是自己
            if (data.userID === store2.get("userID")) {
                this.store.dispatch(setSelfInfo(data));
            }
            this.store.dispatch(updateMessageUser(data));
        });

        // 用户被踢下线
        IMSDK.on(CbEvents.OnKickedOffline, ({ data }) => {
            this.store.dispatch(setKickVisible(true));
        });
        IMSDK.on(CbEvents.OnUserTokenExpired, () => {
            this.store.dispatch(setExpiredVisible(true));
        });
    }

    removeImListener() {
    }

    // 标记群聊或者单聊的已读
    async markConversationAsRead(options: {
        conversationID: string
    } & GetOneConversationParams) {
        try {
            const { data } = await IMSDK.getOneConversation(options);
            if (data.unreadCount > 0) {
                await IMSDK.markConversationMessageAsRead(options.conversationID);
            }
            // 成功回调
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    }

    // 获取指定会话信息
    async getOneConversation(options: GetOneConversationParams) {
        try {
            const { data } = await IMSDK.getOneConversation(options);
            return Promise.resolve(data);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    // 设置当前会话置顶状态
    async setPinConversation(conversationID: string, isPinned: boolean) {
        try {
            await IMSDK.pinConversation({
                conversationID,
                isPinned
            });
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    }

    // 设置当前会话免打扰 setConversationRecvMessageOpt
    async setRecvMessageOpt(conversationID: string, opt: MessageReceiveOptType) {
        try {
            await IMSDK.setConversationRecvMessageOpt({
                conversationID,
                opt
            });
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    }

    // 创建文本消息
    async createTextMessage(text: string) {
        return new Promise<MessageItem>((resolve, reject) => {
            this.IMSDK.createTextMessage(text).then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err);
            });
        });
    }

    // 创建引用信息
    async createQuoteMessage(QuoteMessage: MessageItem, text: string) {
        const res = await this.IMSDK.createQuoteMessage({
            text,
            message: JSON.stringify(QuoteMessage),
        });
        return res.data;
    }

    // 创建语音信息
    async createVoiceMessage(file: File, duration: number) {
        const type = file.type.split(';')[0].split('/')[1] || 'webm';
        // 上传音频文件
        const { url } = await this.updateFile(uid() + "." + type, type, file);
        // 通过音频文件创建语音信息
        const obj = {
            uuid: uid(),
            soundPath: "",
            sourceUrl: url,
            dataSize: file.size,
            duration: Math.floor(duration), // 必须传递整数，浮点报错！
            soundType: type,
            file
        };
        const res = await this.IMSDK.createSoundMessageByFile(obj);
        return res.data;
    }

    // 根据file对象创建图片信息
    async createImageMessage(originUrl: string) {
        const { width, height, url } = await getImageInfo(originUrl);
        const randomUid = uid();
        const options = {
            type: "image",
            id: randomUid,
            url,
            width,
            height,
        };
        return new Promise<MessageItem>((resolve, reject) => {
            this.IMSDK.createCustomMessage({
                data: JSON.stringify(options),
                extension: "",
                description: ""
            }).then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err);
            });
        });
    }

    // 删除信息
    async deleteMessage(conversationID: string, clientMsgID: string, isAll = false) {
        let api = isAll ? this.IMSDK.deleteMessage : this.IMSDK.deleteMessageFromLocalStorage;
        // 删除本地信息和远程信息
        const res = await api({
            conversationID,
            clientMsgID
        });
        // 找到消息， 清除
        this.store.dispatch(deleteMessageFormStore(clientMsgID));
        return res.data;
    }

    // 获取指定群的信息
    async getSpecifiedGroupsInfo(groupId: string) {
        const res = await this.IMSDK.getSpecifiedGroupsInfo([groupId]);
        const [groupInfo] = res.data;
        return groupInfo;
    }

    // 获取群管理员和群主列表
    async getGroupMemberOwnerAndAdmin(groupId: string) {
        try {
            const res = await this.IMSDK.getGroupMemberOwnerAndAdmin(groupId);
            return res.data;
        }catch (err) {
            return []
        }

        // return res.data.filter(item => {
        //     return item.roleLevel !== GroupMemberRole.Nomal;
        // });
    }

    // 禁言或者取消禁言某个群成员
    async muteGroupMember(groupID: string, userID: string, muted: number) {
        const res = await this.IMSDK.changeGroupMemberMute({
            groupID,
            userID,
            mutedSeconds: muted
        });
        return res.data;
    }

    // 获取指定群成员信息
    async getGroupMemberInfo(groupID: string, userID: string) {
        const res = await this.IMSDK.getSpecifiedGroupMembersInfo({
            groupID,
            userIDList: [userID]
        });
        return res.data[0];
    }

    // 获取指定人的信息
    async getUsersInfo(userID: string) {
        const res = await this.IMSDK.getUsersInfoWithCache({
            userIDList: [userID]
        });
        const [singleInfo] = res.data;
        return singleInfo;
    }

    // 获取指定群成员列表
    async getGroupMemberList(groupID: string) {
        try {
            const obj = {
                groupID,
                filter: 0,
                offset: 0,
                count: 5000,
            } as any;
            const res = await this.IMSDK.getGroupMemberList(obj);
            return res.data;
        } catch (err) {
            return [];
        }

    }

    // 发送消息
    async sendMessage(message: SendMsgParams) {
        try {
            const res = await this.IMSDK.sendMessage(message);
            return res.data;
        } catch (err: any) {
            switch (err.errCode) {
                case 1204:
                    toast("the group has been disbanded", "error");
                    break;
                case 5999:
                    toast(err.errMsg.replace(/5999/ig, "").trim(), "error");
                    break;
            }
            // 发送失败
            return Promise.reject(err);
        }
    }

    // 通过sdk上传文件， 返回一个文件地址
    async updateFile(name: string, contentType: string, file: File) {
        try {
            const res = await IMSDK.uploadFile({
                name,
                contentType,
                uuid: uid(),
                file,
            });
            return res.data;
        } catch (err) {
            return Promise.reject(err);
        }

    }
}

export default OpenIm;

