import { createHashRouter, Navigate } from 'react-router-dom';
import Home from "../pages/Home";
import Layout from "@/layout";
import PasswordRoom from "@/pages/PasswordRoom";
import Chat from "@/pages/Chat";
import Empty from "@/pages/Empty";

export default createHashRouter([
    {
        path: "/empty",
        element: <Empty/>
    },
    {
        path: '/',
        element: <Navigate to="/home"></Navigate>
    },
    {
        element: <Layout></Layout>,
        children: [
            {
                path: '/home',
                element: <Home></Home>,
            },
            {
                path: "/password-room",
                element: <PasswordRoom/>
            },
            {
                path: "/chat",
                children: [
                    {
                        index: true,
                        element: <div>no data</div>
                    },
                    {
                        path: ":conversationID",
                        element: <Chat/>
                    }
                ]
            }
        ]
    }
]);
