import { memo, ReactNode, CSSProperties } from "react";

type PersonalCardProps = {
    title: string;
    children?: ReactNode;
    style?: CSSProperties;
}

function PersonalCard(props: PersonalCardProps) {
    return <div className="card" style={props.style}>
        <div className="card-title">{ props.title }</div>
        <div className="card-content">
            {
                props.children
            }
        </div>
    </div>;
}

export default memo(PersonalCard);
