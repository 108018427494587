import React, { useEffect } from 'react';
import { Provider } from "react-redux";
import { RouterProvider } from 'react-router-dom';
import router from "./router";
import store from "@/store";
import { ConfigProvider } from "antd-mobile";
import enUS from "antd-mobile/es/locales/en-US";
import "wc-waterfall";

function App() {
    useEffect(() => {
        document.body.addEventListener("contextmenu", event => {
            event.preventDefault();
        });
    }, []);
    return (
        <>
            <Provider store={ store }>
                <ConfigProvider locale={ enUS }>
                    <RouterProvider router={ router }/>
                </ConfigProvider>
            </Provider>
        </>

    );
}

export default App;
