import { memo, useCallback } from "react";
import { Mask } from "antd-mobile";
import closeIcon from "@/assets/svg/close.svg";
import { useAppDispatch, useAppSelector } from "@/store";
import { setBigVisible, setUserLikeCount, setUserMyLike } from "@/store/slice/personal";
import { setUserLike } from "@/apis/im";
import ToggleHeart from "@/pages/Chat/feature/Modal/PersonalModal/feature/ToggleHeart";
import { toast } from "@/utils";

function AvatarModal() {
    const userState = useAppSelector(state => state.personal.user);
    const bigVisible = useAppSelector(state => state.personal.bigVisible);
    const dispatch = useAppDispatch();
    const toggleLike = useCallback(() => {
        setUserLike({
            userID: userState.userID,
            state: userState.myLike
        }).then(res => {
            if (res.status === 500) {
                toast(res.message, "error");
                return;
            }
            dispatch(setUserLikeCount(res.data.likeCount));
            dispatch(setUserMyLike(res.data.myLike));
        });
    }, [userState]);
    return <Mask visible={ bigVisible }>
        <div className="bigAvatar">
            <div className="bigAvatar-close" onClick={ () => {
                dispatch(setBigVisible(false));
            } }>
                <img src={ closeIcon } alt=""/>
            </div>
            <div className="bigAvatar-pic">
                <img src={ userState.faceURL } alt=""/>
            </div>
            <div className="bigAvatar-like">
                <div className="bigAvatar-like-image">
                    <ToggleHeart state={ userState.myLike } onClick={ toggleLike }/>
                </div>
                <div className="num">{ userState.likeCount }</div>
            </div>
        </div>
    </Mask>;
}

export default memo(AvatarModal);
