import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    lng: "en-US",
    originToken: "",
    chatToken: "",
    imToken: "",
    // 当前用户信息
    user: {
        userID: "",
        nickname: "",
        faceURL: "",
        createTime: 0,
        ex: "",
        attachedInfo: "",
        globalRecvMsgOpt: 0
    },
    loginModalVisible: false,
    editorModalVisible: false,
    twiceVisible: false,
    loading: false,
    kickVisible: false,
    expiredVisible: false
};
export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        updateCommonUser(state, action) {
            state.user = action.payload;
        },
        setLoginModalVisible(state, action) {
            state.loginModalVisible = action.payload;
        },
        setEditorModalVisible(state, action) {
            state.editorModalVisible = action.payload;
        },
        setTwiceVisible(state, action) {
            state.twiceVisible = action.payload;
        },
        setKickVisible(state, action) {
            state.kickVisible = action.payload;
        },
        setExpiredVisible(state, action) {
            state.expiredVisible = action.payload;
        },

        showLoading(state) {
            state.loading = true;
        },
        hideLoading(state) {
            state.loading = false;
        }
    }
});

export const {
    updateCommonUser,
    setLoginModalVisible,
    setEditorModalVisible,
    setTwiceVisible,
    setKickVisible,
    setExpiredVisible,
    showLoading,
    hideLoading
} = commonSlice.actions;
export default commonSlice.reducer;
