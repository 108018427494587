import { Mask } from "antd-mobile";
import logo from "@/assets/circle-loading.png";

function CircleLoading() {
    return <Mask>
        <div className="circle-loading">
            <div className="circle-loading-bg">
                <img src={ logo } alt="" className="circle-loading-bg-logo"/>
            </div>
        </div>
    </Mask>;
}

export default CircleLoading;
