const execCommand = (text: string, callback?: Function) => {
    let oInput = document.createElement('input');
    oInput.setAttribute('readonly', 'readonly');
    oInput.value = text;
    document.body.appendChild(oInput);
    oInput.select();
    document.execCommand('Copy');
    oInput.style.display = 'none';
    oInput.remove();
    callback && callback();
};

export const copyText = (text: string, cb?: () => void) => {
    const clipboardObj = navigator.clipboard;
    try {
        if (clipboardObj) {
            clipboardObj
                .writeText(text)
                .then(() => {
                    cb?.();
                })
                .catch(() => {
                    execCommand(text, cb);
                });
        } else {
            execCommand(text, cb);
        }
    } catch (e) {
        console.warn(e);
    }
};
