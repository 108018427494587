import sumBig from "@/assets/game-pic/dice/big.webp";
import sumSmall from "@/assets/game-pic/dice/small.webp";
import { isNumber } from "@/utils";
import sumOdd from "@/assets/game-pic/dice/odd.webp";
import sumEven from "@/assets/game-pic/dice/even.webp";
import single1 from "@/assets/game-pic/dice/1-red.webp";
import single2 from "@/assets/game-pic/dice/2-black.webp";
import single3 from "@/assets/game-pic/dice/3-black.webp";
import single4 from "@/assets/game-pic/dice/4-red.webp";
import single5 from "@/assets/game-pic/dice/5-black.webp";
import single6 from "@/assets/game-pic/dice/6-black.webp";
import { memo } from "react";

const singleIcon = [single1, single2, single3, single4, single5, single6];

enum DiceType {
    SUM = "sum",
    SIGNLE = "single",
    DOUBLE = "double",
    PAIR = "pair",
    TRIPLE = "triple",
    LEOPARD = "leopard"
}

function getDiceObj(type: DiceType, number: string) {
    if (type === DiceType.SUM && number === "Big") {
        return {
            type,
            icon: [sumBig]
        };
    }
    if (type === DiceType.SUM && number === "Small") {
        return {
            type,
            icon: [sumSmall]
        };
    }
    if (type === DiceType.SUM && isNumber(number)) {
        return {
            type,
            icon: [require(`@/assets/game-pic/dice/num-bg-${+number}.webp`)]
        };
    }
    if (type === DiceType.SUM && number === "Odd") {
        return {
            type,
            icon: [sumOdd],
        };
    }
    if (type === DiceType.SUM && number === "Even") {
        return {
            type,
            icon: [sumEven],
        };
    }
    // [DiceType.TRIPLE,DiceType.LEOPARD,DiceType.PAIR,DiceType.DOUBLE].includes(type)
    if ([DiceType.TRIPLE, DiceType.LEOPARD].includes(type)) {
        const text = number.charAt(0);
        return {
            type,
            icon: Array(3).fill(singleIcon[+text - 1]),
        };
    }
    if ([DiceType.DOUBLE, DiceType.PAIR].includes(type)) {
        const text = number.charAt(0);
        return {
            type,
            icon: Array(2).fill(singleIcon[+text - 1]),
        };
    }
    return {
        type,
        icon: [singleIcon[+number - 1]],
    };
}

type DiceBallProps = {
    type: any;
    value: string
}

function ReflexDice(props: DiceBallProps) {
    const { type, value } = props;
    const obj = getDiceObj(type.toLowerCase(), value);
    return <div className="reflex-container">
        <div className="reflex">
            {
                obj.icon.map((pic, i) => {
                    return <div className="reflex-item" key={ i }
                                style={ { backgroundImage: `url(${ pic })`, backgroundSize: "100% 100%" } }>
                    </div>;
                })
            }
        </div>
    </div>;
}

export default memo(ReflexDice);
