import React from "react";
import { MessageItem } from "open-im-sdk/lib/types/entity";
import { Image, ImageViewer } from "antd-mobile";
import MessageState from "@/pages/Chat/feature/MessageItem/MessageState";

type ImageMessageProps = {
    data: MessageItem;
    image: {
        url: string;
        width: number,
        height: number,
    }
}
export default function PictureMessage(props: ImageMessageProps) {
    const { data, image } = props;
    const { width, height, url } = image;
    let localWidth = width > 150 ? 150 : width;
    const scale = localWidth / width;
    const localHeight = height * scale;
    return <>
        <div className="image-message" style={{width: localWidth, height: localHeight}}>
            <Image onClick={() => {
                ImageViewer.show({ image: url })
            }} className="image-message-img" src={ url }
                   alt={ url }></Image>
        </div>
        <MessageState data={ data } style={ { marginTop: 4, float: "right" } }></MessageState>
    </>;
}
