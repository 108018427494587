// 中转，根据不同的type展示不同的卡片
import { MessageItem } from "open-im-sdk/lib/types/entity";
import { Image } from "antd-mobile";
import ShareFollowed from "./shareFollowed";
import React, { memo, useEffect, useRef, useState } from "react";
import ColorGame from "./Color";
import Dice from "@/pages/Chat/feature/ShareCards/Dice";
import Digit from "@/pages/Chat/feature/ShareCards/Digit";
import Satta from "@/pages/Chat/feature/ShareCards/Satta";
import CountTime from "@/components/CountTime";
import LuckMessage from "@/pages/Chat/feature/MessageItem/LuckMessage";
import type { CardItemItem } from "@/utils";
import classNames from "classnames";
import { TGameCode } from "@/store/slice/followed";
import QuickRace from "@/pages/Chat/feature/ShareCards/QuickRace";
import QuickComprehensive from "@/pages/Chat/feature/ShareCards/QuickComprehensive";
import { useScroll } from "ahooks";

export type CardTypeData = {
    shareId: string;
    shareName: string;
    rawData: any;
    gameIconUrl: string;
    gameName: string;
    issNo: string;
    gameType: TGameCode;
    openTime: number;
    result: string;
    totalAmount: number;
    totalAwardAmount: number;
    from?: "order";
    count?: number;
    list: CardItemItem[];
}
type ShareCardsProps = {
    self: boolean;
    data: MessageItem;
    quote?: boolean // 是否是引用信息
}
const renderCardContent = (type: TGameCode, data: CardItemItem[]) => {
    switch (type) {
        case "color":
            return <ColorGame data={ data }/>;
        case "dice":
            return <Dice data={ data }/>;
        case "3Digit":
        case "quick3d":
            return <Digit data={ data }/>;
        case "satta":
        case "matka":
            return <Satta data={ data }/>;
        case "quickRace":
            return <QuickRace data={ data }/>;
        case "quickStateLottery":
        case "stateLottery":
            return <QuickComprehensive data={ data }/>;
        default:
            return <div></div>;
    }
};

function ShareCards(props: ShareCardsProps) {
    const { self, data: messageData, quote } = props;
    const [cardData, setCardData] = useState({} as CardTypeData);
    const drawTime = cardData.openTime;
    const [resetEnd, setResetEnd] = useState(false);
    const [isEnd, setIdEnd] = useState(true);
    const scrollContent = useRef<HTMLDivElement>(null);
    const [showShadow, setShowShadow] = useState(false);
    const scroll = useScroll(scrollContent);

    useEffect(() => {
        const scrollContentDom = scrollContent.current;
        if (scrollContentDom) {
            const isShow = scrollContentDom.scrollWidth > scrollContentDom.offsetWidth + scroll?.left!;
            setShowShadow(isShow);
        }
    }, [cardData.list, scroll]);
    useEffect(() => {
        try {
            let customData = JSON.parse(messageData.customElem.data);
            if (customData.userId) {
                const needType = [
                    "matka",
                    "satta",
                    "scratch",
                    "statelottery",
                    "3digit",
                    "quick3d"
                ];
                let gameName = customData.data.gameName;
                if (needType.includes(customData.data.gameType.toLowerCase())) {
                    gameName = [customData.data.gameType, customData.data.gameName].join("-");
                }
                setCardData({
                    ...customData.data,
                    shareId: customData.userId,
                    shareName: messageData.senderNickname,
                    gameName
                });
                // 判断当前是否已经结束
                if (customData.data.openTime > Date.now()) {
                    setIdEnd(false);
                }
                setResetEnd(true);

            }
        } catch (err) {
            // console.log(messageData.customElem);
        }
    }, []);
    // 判断是否是luck是的话
    if (cardData.totalAwardAmount) {
        return <LuckMessage self={ self } data={ messageData } cardData={ cardData }></LuckMessage>;
    }
    return <div className={ classNames("chat-card", {
        quote
    }) }>
        <div className="card-message">
            <div className="card-top">
                <Image src={ cardData.gameIconUrl } className="game-icon"></Image>
                <div className="card-title">
                    <div className="title">{ cardData.gameName }</div>
                    <div className="sub-title">
                        {
                            isEnd ? resetEnd ? "Prize drawn" : "" : <CountTime remindTime={ drawTime } onEnd={ () => {
                                setIdEnd(true);
                            } }></CountTime>
                        }
                    </div>
                </div>
                <div className="payment">
                    <div className="top">Payment</div>
                    <div className="price">₹{ cardData.totalAmount?.toFixed(2) || 0.00 }</div>
                </div>
            </div>
            {
                cardData.list && cardData.list.length > 0 && <>
                    <div className="line"></div>
                    <div className={ classNames("content-wapper", { shadow: showShadow }) }>
                        <div className="content" ref={ scrollContent }>
                            {
                                renderCardContent(cardData.gameType || "dice", cardData.list || [])
                            }
                        </div>
                    </div>

                </>
            }
        </div>
        <ShareFollowed self={ self } data={ cardData } isEnd={ isEnd }/>
    </div>;
}

export default memo(ShareCards);
