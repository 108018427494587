import React from "react";
import { MessageItem } from "open-im-sdk/lib/types/entity";
import { useAppSelector } from "@/store";
import classNames from "classnames";
import MessageState from "@/pages/Chat/feature/MessageItem/MessageState";
import { MessageType } from "open-im-sdk";
import { formattedTime } from "@/utils/utils";
import voiceIcon from "@/assets/svg/voice-message.svg";
import { Ellipsis, Image, Space } from "antd-mobile";
import ShareCards from "@/pages/Chat/feature/ShareCards";
import { emitter, getVmSize } from "@/utils";

type QuoteMessage = {
    data: MessageItem
}
// eslint-disable-next-line
export default function QuoteMessage(props: QuoteMessage) {
    const { chatInfo, list } = useAppSelector(state => state.chat);
    const { data } = props;
    const quotoMessage = data.quoteElem.quoteMessage;
    const content = data.quoteElem.text;
    // 判断是否是自己的信息
    const isSelf = chatInfo.currentUserID === quotoMessage.sendID;
    const gotoIndex = () => {
        const currentIndex = list.findIndex(item => item.clientMsgID === quotoMessage.clientMsgID);
        if (~currentIndex) {
            emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "auto", index: currentIndex });
        }
    };

    const renderContent = () => {
        switch (quotoMessage.contentType) {
            case MessageType.TextMessage:
                return <Ellipsis style={ { lineHeight: getVmSize(16) } } content={ quotoMessage.textElem.content }
                                 rows={ 3 }></Ellipsis>;
            case MessageType.VoiceMessage:
                const time = formattedTime(quotoMessage.soundElem.duration * 1000).join(":");
                return <div className="voice-quoto-message">
                    <img className="icon" src={ voiceIcon } alt=""/>
                    <span>voice ({ time })</span>
                </div>;
            case MessageType.PictureMessage:
                return <Space>
                    <Image width={ 100 } src={ quotoMessage.pictureElem.sourcePicture.url }></Image>
                </Space>;
            case MessageType.CustomMessage:
                const data = JSON.parse(quotoMessage.customElem.data);
                if (data.type === "image") {
                    return <Space>
                        <Image width={ 100 } src={ data.url }/>
                    </Space>;
                }
                return <ShareCards self={ isSelf } data={ quotoMessage } quote={ true }></ShareCards>;
            default:
                return "error quote type";
        }
    };
    return <div>
        <div className={ classNames("quote-message", { isSelf }) } onClick={ gotoIndex }>
            <div className="quote-message-name">{ isSelf ? "YOU" : quotoMessage.senderNickname }</div>
            <div className="quote-message-content">{ renderContent() }</div>
        </div>
        <span>
            { content }
        </span>
        <MessageState style={ { float: "right" } } data={ data }></MessageState>
    </div>;
}
