import { useEffect } from "react";
import { useCountDown as useAcountDown, useLatest } from "ahooks";
import { addZero } from "@/utils/utils";

export function useCountDown(remindTime: string | number, onEnd?: () => void) {
    const targetDate = useLatest(remindTime)
    const [countdown, formattedRes] = useAcountDown({
        targetDate: targetDate.current,
        interval: 500,
        onEnd: () => {
            onEnd?.();
        }
    });
    const { minutes, seconds, hours, days } = formattedRes;
    useEffect(() => {
        if (countdown <= 0) {
            onEnd?.();
        }
    }, [countdown]);
    return {
        minutes: addZero(minutes),
        seconds: addZero(seconds),
        hours: addZero(hours),
        days
    };
}
