import React, { useCallback, useEffect, useRef, useState } from "react";
import { Image, DotLoading } from "antd-mobile";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import ChatItem from "@/pages/Chat/feature/ChatItem";
import { emitter } from "@/utils";
import useChatInfo from "@/pages/Chat/feature/hooks/useChatInfo";
import { ChatMessageItem } from "@/store/slice/chat";
import { useLatest, useThrottleFn } from "ahooks";
import { SystemMessageTypes } from "@/openIm/constants";
import SystemNotification from "@/pages/Chat/feature/SystemNotification";
import classNames from "classnames";
import { useAppSelector } from "@/store";

function ChatMain() {
    const { list, loading } = useChatInfo();
    const voiceVisble = useAppSelector(state => state.voice.boxVisible);
    const latestList = useLatest(list);
    const [bottomBtn, setBottomBtn] = useState(false);
    const handleToBottom = useCallback((config: { behavior?: "smooth" | "auto", index?: number }) => {
        const { behavior = "auto", index } = config || {};
        setTimeout(() => {
            virtualRef.current?.scrollToIndex({
                index: index ? index : latestList.current.length - 1,
                behavior
            });
        }, 50);

    }, []);
    const addMessage = () => {
        if (!bottomBtn) {
            handleToBottom({ behavior: 'smooth' });
        }
    };
    const virtualRef = useRef<VirtuosoHandle>(null);
    const warpRef = useRef<HTMLDivElement>(null);

    const rowRenderer = (_: any, data: ChatMessageItem) => {
        if (SystemMessageTypes.includes(data.contentType)) {
            return <SystemNotification data={ data } key={ data.clientMsgID }/>;
        }
        return <ChatItem data={ data } key={ data.clientMsgID }></ChatItem>;
    };
    // 导航栏滚动到最底部显示按钮
    const { run: handleScroll } = useThrottleFn(() => {
        const target = document.querySelector("#virtual") as HTMLDivElement;
        const limitValue = 10;
        if (!target) return;
        const scrolltop = target.scrollTop;
        const offsetHeight = target.offsetHeight;
        const scrollHeight = target.scrollHeight;
        if (scrolltop + offsetHeight + limitValue < scrollHeight) {
            if (bottomBtn) return;
            setBottomBtn(true);
        } else {
            if (!bottomBtn) return;
            setBottomBtn(false);
        }
    }, { wait: 500 });
    useEffect(() => {

        emitter.on("CHAT_LIST_SCROLL_TO_BOTTOM", handleToBottom);
        emitter.on("CHAT_LIST_SCROLL_BY_SHOW_BTN", addMessage);
        return () => {
            emitter.off("CHAT_LIST_SCROLL_TO_BOTTOM", handleToBottom);
            emitter.off("CHAT_LIST_SCROLL_BY_SHOW_BTN", addMessage);
        };
    }, []);
    useEffect(() => {
        handleScroll();
    }, [list]);
    return <div className="chat-main-container">
        <div className={ classNames("chat-main-inner") } ref={ warpRef }>

            {
                loading ? <div className="loading">
                    <DotLoading color="#008069"/>
                    <span>Loading</span>
                </div> : <Virtuoso
                    ref={ virtualRef }
                    data={ list }
                    id="virtual"
                    increaseViewportBy={ 600 }
                    computeItemKey={ (_, item) => item.clientMsgID }
                    totalCount={ list.length }
                    onScroll={ handleScroll }
                    itemContent={ rowRenderer }
                ></Virtuoso>
            }

        </div>
        {
            bottomBtn && !voiceVisble && <Image onClick={ () => handleToBottom({ behavior: "smooth" }) }
                                                src={ require('@/assets/svg/to-bottom.svg').default }
                                                className="chat-to-bottom"></Image>
        }

    </div>;
}

export default ChatMain;
