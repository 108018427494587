import ReflexDice from "@/pages/Chat/feature/Reflex/ReflexDice";
import type { CardItemItem } from "@/utils";

type DiceProps = {
    data: CardItemItem[]
}

function Dice(props: DiceProps) {
    const { data } = props;
    return <div className="dice-game">
        {
            data.map((item,i) => {
                return <div className="dice-game-item" key={i}>
                    <ReflexDice type={ item.playType } value={ item.number as string }/>
                    <div className="price">₹{ item.amount.toFixed(2) } </div>
                </div>;
            })
        }

    </div>;
}

export default Dice;
