import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Image } from "antd-mobile";
import InputExpression from "./InputExpression";
import TextArea from "rc-textarea";
import faceIcon from "@/assets/svg/face.svg";
import keyboardIcon from "@/assets/svg/keyboard.svg";
import voiceIcon from "@/assets/svg/voice.svg";
import sendIcon from "@/assets/svg/fly.svg";
import { ImContext } from "@/openIm";
import { addNewMessage, setQuoteMessage, setSelfMuted, updateMessage } from "@/store/slice/chat";
import { useAppDispatch, useAppSelector } from "@/store";
import { authLogin, emitter, isDefaultUser, isVisitorUser } from "@/utils";
import Quote from "@/pages/Chat/feature/InputFoot/Quote";
import classNames from "classnames";
import { MessageStatus } from "open-im-sdk";
import type { MessageItem, SendMsgParams } from "open-im-sdk";
import { setVoiceBoxVisible } from "@/store/slice/voice";
import { useCurrentUser } from "@/pages/Chat/feature/hooks/useCurrentUser";
import { useCountDown } from "@/hooks/useCountDown";

const BASE_CARD_HEIGHT = 0;
const InputFoot = () => {
    const dispatch = useAppDispatch();
    const { chatInfo, quoteMessage, selfInfo } = useAppSelector(state => state.chat);
    const { muted } = useCurrentUser();
    const { openIm } = useContext(ImContext);
    const [showFace, setShowFace] = useState(false);
    const leftIcon = showFace ? keyboardIcon : faceIcon;
    const [expressHeight, setExpressHeight] = useState(BASE_CARD_HEIGHT);
    const [chatMessage, setChatMessage] = useState("");
    const voiceRef = useRef<any>();
    const textAreaRef = useRef<any>();
    const inputBoxRef = useRef<any>();
    const [textAreaHeight, setTextAreaHeight] = useState(27);
    const countDownTime = useCountDown(selfInfo.muteEndTime, () => {
        dispatch(setSelfMuted(false));
    });
    const remainingText = useMemo(() => {
        const { days, seconds, minutes, hours } = countDownTime;
        let text = "";
        if (days > 0) {
            text += countDownTime.days;
        }
        text += [hours, minutes, seconds].join(":");
        return text;
    }, [countDownTime]);
    const focusInput = useCallback(() => {
        textAreaRef.current?.focus();
    }, []);
    // 获取当前光标的位置
    const getCursorPosition = () => {
        const input = textAreaRef.current;
        if (input) {
            const textArea = input.resizableTextArea.textArea;
            return [textArea.selectionStart, textArea.selectionEnd];
        }
        return [0, 0];
    };
    const changeChatMessage = (e: any) => {
        let value = e.target.value;
        setChatMessage(value);
    };
    // 删除输入框内容
    const handleDelete = () => {
        let [start] = getCursorPosition();
        if (chatMessage.length === 1) {
            setChatMessage("");
            return;
        }
        let prev = chatMessage.substring(0, start);
        const next = chatMessage.substring(start);
        let num = 1;
        if (prev.length <= 1) {
            prev = "";
        } else {
            num = prev.substring(prev.length - 2, prev.length).search(/(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f])|(\ud83d[\ude80-\udeff])/i) === -1 ? 1 : 2;
            prev = prev.substring(0, prev.length - num);
        }
        setTimeout(() => {
            const input = textAreaRef.current.resizableTextArea.textArea;
            input.focus();
            input.setSelectionRange(start - num, start - num);
        });
        setChatMessage(prev + next);
    };
    const onHeightChange = (height: number) => {
        setExpressHeight(height);
    };
    // 重置弹窗高度
    const resetHeight = () => {
        setExpressHeight(BASE_CARD_HEIGHT);
        setShowFace(false);
    };
    // 打开表情弹窗
    const chooseExpression = () => {
        if (authLogin()) {
            resetHeight();
            setShowFace(!showFace);
        }
    };
    const chooseItem = async (type: "image" | "emoji", value: string) => {
        if (type === "emoji") {
            setChatMessage(prev => {
                return prev + value;
            });
        }
        if (type === "image") {
            // 创建一个文件对象
            try {
                const message = await openIm.createImageMessage(value);
                // 发送信息
                sendMessage({
                    recvID: chatInfo.userID,
                    groupID: chatInfo.groupID,
                    message
                });
            } catch (err) {
                console.log(err);
            }

        }
    };

    const renderButton = () => {
        return chatMessage.trim().length > 0 ? sendButton() : voiceButton();
    };
    const voiceButton = () => {
        const handleVoice = () => {
            if (!authLogin()) return;
            if (isDefaultUser()) return;
            dispatch(setVoiceBoxVisible(true));
        };
        return <div
            ref={ voiceRef }
            className={classNames("chat-right", {visitor: isVisitorUser()})}
            onClick={ handleVoice }
            // onTouchMove={ handleMove }
        >
            <div className={ classNames("chat-input-button") }>
                <Image placeholder="" className="chat-input-button_icon" src={ voiceIcon }></Image>
            </div>
        </div>;
    };
    const sendButton = () => {
        return <div className="chat-right">
            <div className="chat-input-button" onClick={ handleSendMessage }>
                <Image placeholder="" className="chat-input-button_icon" src={ sendIcon }></Image>
            </div>
        </div>;
    };
    const sendMessage = async (options: SendMsgParams) => {
        const { message, recvID, groupID } = options;
        if (isDefaultUser()) {
            return;
        }
        dispatch(addNewMessage(message));
        Promise.resolve().then(() => {
            emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "smooth" });
        });
        // 清空数据
        setChatMessage("");
        // 清空引用
        dispatch(setQuoteMessage(null));
        try {
            const res = await openIm.sendMessage({
                message,
                recvID,
                groupID
            });
            dispatch(updateMessage(res));
        } catch (err) {
            dispatch(updateMessage({ ...message, status: MessageStatus.Failed }));
        }

    };
    // 发送一条信息
    const handleSendMessage = async () => {
        if (!authLogin()) return;
        if (isDefaultUser()) return;
        // 根据不同的类型 产出不同的信息
        // 引用信息， 引用
        let message: MessageItem;
        if (quoteMessage) {
            message = await openIm.createQuoteMessage(quoteMessage, chatMessage);
        } else {
            // 文本
            message = await openIm.createTextMessage(chatMessage.trim());
        }
        sendMessage({
            message,
            recvID: chatInfo.userID,
            groupID: chatInfo.groupID
        });
    };
    useEffect(() => {
        emitter.on("INPUT_FOCUS", focusInput);
        return () => {
            emitter.off("INPUT_FOCUS", focusInput);
        };
    }, []);
    const resizeShow = useMemo(() => {
        return !!quoteMessage;
    }, [textAreaHeight, quoteMessage]);
    const renderInputInner = () => {
        return <div className={ classNames("chat-textarea") }>
            <div onClick={ chooseExpression } className="chat-input-icon">
                <img src={ leftIcon } alt=""/>
            </div>
            <TextArea
                disabled={ isVisitorUser() || muted }
                ref={ textAreaRef }
                value={ chatMessage }
                onChange={ changeChatMessage }
                onResize={ size => {
                    setTextAreaHeight(size.height);
                } }
                className="chat-input"
                onFocus={ () => {
                    setShowFace(false);
                } }
                autoSize={ { maxRows: 11 } }
                placeholder={ "Message" }
            ></TextArea>
        </div>;
    };
    const renderBottomInput = () => {
        if (isVisitorUser()) return <div className="disabledInput">Register first, then message freely</div>;
        if (selfInfo.muted) return <>
            <div className="disabledInput" >
                You are muted
                {/*, remaining: { remainingText }*/ }
            </div>
        </>;
        return renderInputInner();
    };
    return (
        <>
            <div className={ classNames("chat-input-conatiner") } onClick={ () => {
                if (isVisitorUser()) {
                    authLogin();
                }
            } }>
                <div className={ classNames("chat-input-content", { disabled: isVisitorUser() || muted, resizeShow }) }
                     ref={ inputBoxRef }>
                    <Quote/>
                    <div className="chat-input-box">
                        {
                            renderBottomInput()
                        }
                    </div>
                </div>
                {
                    selfInfo.muted ? null : renderButton()
                }
            </div>
            <div style={ { height: expressHeight, overflow: "hidden" } }>
                <InputExpression
                    visible={ showFace }
                    setVisible={ setShowFace }
                    chooseItem={ chooseItem }
                    onHeightChange={ onHeightChange }
                    handleDelete={ handleDelete }
                ></InputExpression>
            </div>
        </>

    );
};

export default InputFoot;
