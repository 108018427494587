import type { ReactElement } from "react";
import { createRoot } from "react-dom/client";
export function renderToBody(element: ReactElement) {
    const container = document.createElement("div");
    document.body.appendChild(container);
    const root = createRoot(container);

    function unmount() {
        root.unmount();
        if (container.parentNode) {
            container.parentNode.removeChild(container);
        }
    }
    root.render(element);
    return unmount;
}
