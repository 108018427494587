import Navbar from "@/components/Navbar";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "@/store";
import { Image } from "antd-mobile";
import { getVmSize, sendBackMessage } from "@/utils";
import defaultPic from "@/assets/svg/defalt-chat.svg";

function ChatHeader() {
    const totalUnread = useAppSelector(state => state.home.totalUnread);
    const chatStore = useAppSelector(state => state.chat);
    const homeStore = useAppSelector(state => state.home);
    const homeRouter = useMatch("/home");
    const navigate = useNavigate();
    const info = chatStore.groupInfo;

    const title = chatStore.groupInfo?.groupName;

    const renderTitle = () => {
        return <div className="title">
            <Image className="faceIcon"
                   placeholder={ defaultPic }
                   fallback={ <Image src={ defaultPic }/> }
                   src={ info?.faceURL || defaultPic }></Image>
            <span>{ title }</span>
        </div>;
    };
    return <div className="layout-header">
        <Navbar
            badge={ homeRouter ? totalUnread : 0 }
            title={ renderTitle() }
            showBack={ !homeRouter }
            onBack={ () => {
                if (homeStore.list.length > 1) {
                    navigate("/home");
                } else {
                    sendBackMessage();
                }

            } }></Navbar>
        <div style={ { height: getVmSize(8), background: chatStore.isSelect ? "#F6F5F3" : "#fff"}}></div>
    </div>;
}

export default ChatHeader;
