import { Image } from "antd-mobile";
import backIcon from "@/assets/svg/back-arrow.svg";
import { setSettingVisible } from "@/store/slice/chat";
import defaultChat from "@/assets/svg/defalt-chat.svg";
import dot from "@/assets/svg/dot.svg";
import { useAppDispatch, useAppSelector } from "@/store";
import { useScroll } from "ahooks";

export default function Header() {
    const dispatch = useAppDispatch();
    const scroll = useScroll(document.querySelector("#setting-modal"));
    const { groupDetail, memberList } = useAppSelector(state => state.groupSetting);
    return <div className="group-header"
                style={ { transform: `translateY(${ scroll && scroll.top > 160 ? 0 : -100 }px)` } }
    >
        <Image src={ backIcon } onClick={ () => dispatch(setSettingVisible(false)) }
               className="group-header-back"></Image>
        <div className="group-header-title">
            <Image className="group-header-title-icon" src={ groupDetail?.faceURL }
                   fallback={ <Image src={ defaultChat }/> }></Image>
            <div className="group-header-content">
                <div className="group-header-title-text">{ groupDetail?.groupName }</div>
                <div className="group-header-title-member">
                    Group <Image style={ { margin: "0 8px" } } src={ dot } width={ 2 }
                                 height={ 2 }/> { memberList.length } participants
                </div>
            </div>

        </div>
    </div>;
}
