import { Image } from "antd-mobile";
import React from "react";
import { useAppDispatch } from "@/store";
import { openEndModal, openFollowedModal } from "@/store/slice/followed";
import { CardTypeData } from "@/pages/Chat/feature/ShareCards/index";
import { authLogin, EventType, sendPostMessage } from "@/utils";

const avatarList = Array(12).fill(0).map((item, index) => {
    return require(`@/assets/avatar/image ${ 692 + index }.webp`);
});
// 需要根据是否是发送者来判断 显示不同的样式
// 发送者 显示跟随的人数
// 接受者，显示 跟随按钮
type ShareFollowedProps = {
    self: boolean;
    data: CardTypeData;
    isEnd: boolean;
}

function ShareFollowed(props: ShareFollowedProps) {
    const { self, data, isEnd } = props;
    const avatarIndex = (data.openTime || Date.now()) % 9;
    const list = avatarList.slice(avatarIndex, avatarIndex + 3);
    const dispatch = useAppDispatch();
    const AvatarGourp = () => {
        return <div className="my-avatar-group">
            <div className="avatar-group-user">
                {
                    list.map((item, index) => {
                        return <Image src={ item } key={ index } className="avatar-image"></Image>;
                    })
                }
            </div>
        </div>;
    };
    const renderSelf = () => {
        return <div className="followed-self">
            <div className="followed-left">Followed user:</div>
            <AvatarGourp/>
        </div>;
    };
    const renderOther = () => {
        // 需要根据不同的类型 展示不同的modal
        const handlerClick = () => {
            // 判断是否是游客，如果是的话，就进入登录
            if (!authLogin()) return;
            if (!data.list) {
                sendPostMessage({
                    event: EventType.PLAY,
                    data
                });
                return;
            }
            if (isEnd) {
                dispatch(openEndModal(data));
            } else {
                dispatch(openFollowedModal(data));
            }
        };
        const text = isEnd ? "Play Now" : "Followed Betting";
        return <div className="followed-other">
            <AvatarGourp/>
            <div className="followed-button" onClick={ handlerClick }> { text } </div>
        </div>;
    };
    return <>
        { self ? renderSelf() : renderOther() }
    </>;
}

export default ShareFollowed;
