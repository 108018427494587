import {CenterPopup, Image} from "antd-mobile";
import modalIcon from "@/assets/modal-trumpet.png";
import React, {useEffect} from "react";

let hasShow = true;

function WelcomeModal() {
    const [visible, setVisible] = React.useState(false);
    const showModal = () => {
        if (hasShow) {
            return;
        }
        setVisible(true);
        hasShow = true;
    };
    useEffect(() => {
        showModal();
    }, []);
    return <CenterPopup
        onMaskClick={() => setVisible(false)}
        visible={visible}
        getContainer={() => document.body}
        bodyClassName="home-modal-body"
        className="home-modal"
    >
        <Image src={modalIcon} className={"home-modal-image"}></Image>
        <div className="home-modal-tip">Welcome to our chat room!</div>
        <div className="home-modal-text">
            Welcome to our chat room! 🌟 Here, you can not only enjoy pleasant conversations but also share your winning
            experiences to inspire others. Don't forget, we also distribute regular benefits! Participate in
            discussions, seize opportunities, and make every interaction full of surprises. We look forward to your
            amazing shares! 😊
        </div>
    </CenterPopup>;
}

export default WelcomeModal;
