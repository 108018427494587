import React, { useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Image, Space } from "antd-mobile";
import { useLongPress } from "ahooks";
import defaultChat from "@/assets/svg/defalt-chat.svg";
// import msgRead from "@/assets/svg/msg-read.svg";
// import msgUnRead from "@/assets/svg/msg-unread.svg";
import voiceIcon from "@/assets/svg/voice-message.svg";
import photoIcon from "@/assets/svg/photo.svg";
import gameIcon from "@/assets/svg/gameIcon.svg";
import { ConversationItem, MessageItem as MessageItemIm } from "open-im-sdk/lib/types/entity";
import { formatConversionTime, formattedTime, parseStr } from "@/utils/utils";
import { MessageType } from "open-im-sdk";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "@/store";
import { addSelectItem, showSetting } from "@/store/slice/home";

type MessageItemProps = {
    data: ConversationItem
}

function getLastMeg(lastMsg: string) {
    const msg = parseStr(lastMsg) as MessageItemIm;
    const { textElem, senderNickname, isRead, notificationElem, contentType, quoteElem } = msg;
    // 判断是什么类型的信息
    const obj: any = {
        senderNickname,
        isRead,
        content: "",
        notificationElem
    };
    switch (contentType) {
        case MessageType.GroupAnnouncementUpdated:
            // 处理群公告
            if (notificationElem) {
                const data = parseStr(notificationElem.detail);
                const { opUser } = data;
                obj.content = `${ opUser.nickname }:modified group announcement`;
            }
            break;
        case MessageType.QuoteMessage:
            obj.content = `${ senderNickname }:${ quoteElem.text }`;
            break;
        case MessageType.TextMessage:
            // 处理文本
            if (textElem && textElem.content) {
                obj.content = `${ senderNickname }:${ textElem.content }`;
            }
            break;
        case MessageType.RevokeMessage:
            // 撤回信息
            if (notificationElem) {
                const data = parseStr(notificationElem.detail);
                obj.content = `${ data.revokerNickname }:revoke a messsage`;
            }
            break;
        case MessageType.PictureMessage:
            obj.content = <span>
                { senderNickname }:
                <img src={ photoIcon } className="text-inner-image" alt=""/>
                Photo
            </span>;
            break;
        case MessageType.GroupCancelMuted:
            const GROUPCANCELMUTEDDetails = JSON.parse(
                notificationElem.detail
            );
            const groupCancelMuteOpUser = GROUPCANCELMUTEDDetails.opUser;
            obj.content = `${ groupCancelMuteOpUser } all bans closed`;
            break;
        case MessageType.GroupCreated:
            const groupCreatedDetail = JSON.parse(msg.notificationElem.detail);
            const groupCreatedUser = groupCreatedDetail.opUser;
            obj.content = `${ groupCreatedUser.nickname } created group chat`;
            break;
        case MessageType.VoiceMessage:
            obj.content = <Space style={ { "--gap": "0px" } }>
                <span>{ senderNickname }:</span>
                <img src={ voiceIcon } alt="" className="text-inner-image"/>
                <span>{ formattedTime(msg.soundElem.duration * 1000).join(":") }</span>
            </Space>;
            break;
        case MessageType.CustomMessage:
            const data = JSON.parse(msg.customElem.data);
            if (data.type === "image") {
                obj.content = <Space style={ { "--gap": "0px" } }>
                    { senderNickname }:
                    <img src={ photoIcon } className="text-inner-image" alt=""/>
                    GIF
                </Space>;
            } else {
                obj.content = <Space style={ { "--gap": "0px" } }>
                    { senderNickname }:
                    <img src={ gameIcon } className="text-inner-image" alt=""/>
                    Bet Slip
                </Space>;
            }
            break;
        case MessageType.GroupDismissed:
            const groupDismissedDetail = JSON.parse(msg.notificationElem.detail);
            obj.content = `${ groupDismissedDetail.opUser.nickname }:dismissed the group chat`;
            break;
        case MessageType.GroupMemberMuted:
            const mutedData = JSON.parse(msg.notificationElem.detail);
            obj.content = `${ mutedData.opUser.nickname } banned ${ mutedData.mutedUser.nickname }`;
            break;
        case MessageType.GroupMemberCancelMuted:
            const cancelMutedData = JSON.parse(msg.notificationElem.detail);
            obj.content = `${ cancelMutedData.opUser.nickname } banned ${ cancelMutedData.mutedUser.nickname }`;
            break;
        case MessageType.MemberInvited:
            const inviteDetails = JSON.parse(msg.notificationElem.detail);
            const inviteOpUser = inviteDetails.opUser;
            const invitedUsers = inviteDetails.invitedUserList.map((item: any) => item.nickname);
            obj.content = <>
                {/*{ inviteOpUser.nickname } invited { invitedUsers.join("、") } to join the group chat*/ }
                { invitedUsers.join("、") } join the group chat
            </>;
            break;
        default:
            obj.content = `receive a new message`;
            break;
    }
    return obj;

}

function MessageItem(props: MessageItemProps) {
    const { data } = props;
    const handlerList = useMemo(() => [
        {
            name: "muted",
            show: data.recvMsgOpt !== 0,
            icon: require("@/assets/svg/muted.svg").default
        },
        {
            name: "top",
            show: data.isPinned,
            icon: require("@/assets/svg/top.svg").default
        },
        {
            name: "clock",
            show: false,
            icon: require("@/assets/svg/clock.svg").default
        },

    ], [data]);
    const navigate = useNavigate();
    const messageRef = useRef<HTMLDivElement>(null);
    const { setting, selectList } = useAppSelector(state => state.home);
    const dispatch = useAppDispatch();
    const handleClick = () => {
        if (!status.current) {
            return;
        }
        if (setting) {
            dispatch(addSelectItem(data));
        } else {
            handleChat();
        }
    };
    const status = useRef(false);
    useLongPress(() => {
        if (!setting) {
            dispatch(addSelectItem(data));
            dispatch(showSetting());
        }
    }, messageRef, {
        delay: 500,
        moveThreshold: {
            x: 20,
            y: 10
        },
        onClick() {
            status.current = true;
        },
        onLongPressEnd: () => {
            status.current = false;
        }
        // onClick: () => {
        //     if (setting) {
        //         dispatch(addSelectItem(data));
        //     } else {
        //         handleChat();
        //     }
        // }
    });
    const handleChat = () => {
        // 添加一些配置， 如果存在userID那么就是私聊，如果存在groupID那么就是群聊
        navigate(`/chat/${ data.conversationID }?groupID=${ data.groupID }&userID=${ data.userID }`);
    };
    const { latestMsg } = data;

    const normalzizeLatestMsg = useMemo(() => {
        return getLastMeg(latestMsg);
    }, [latestMsg]);
    const isSelect = () => {
        const index = selectList.findIndex(item => item.conversationID === data.conversationID);
        return index > -1;
    };

    return (
        <div className={ classNames("message-item-box", { select: isSelect() }) } onClick={handleClick}>
            <div className="message-item" ref={ messageRef }>
                <div className="message-item-icon">
                    <Image className="image"
                           src={ data.faceURL + "?x-oss-process=image/resize,h_150,m_lfit" || defaultChat }
                           fallback={ <Image src={ defaultChat }></Image> }
                           lazy
                    />
                </div>
                <div className="message-item-content">
                    <div className="message-item-content-top">
                        <div className="title">
                            { data.showName }
                        </div>
                        <div className={ classNames("time", { "time-read": data.unreadCount === 0 }) }>
                            {
                                formatConversionTime(data.latestMsgSendTime)
                            }
                        </div>
                    </div>
                    <div className="message-item-content-bottom">

                        <div className="text">
                            {
                                normalzizeLatestMsg.content ? <>
                                    {/*<Image className="text-icon" src={ normalzizeLatestMsg.isRead ? msgRead : msgUnRead }></Image>*/ }
                                    <span className="text-content">{ normalzizeLatestMsg.content }</span></> : null
                            }
                        </div>
                        <div className="handler">
                            {
                                handlerList.filter(item => item.show).map(item => {
                                    return <Image key={ item.name } className="handler-icon" src={ item.icon }></Image>;
                                })
                            }
                            {
                                data.unreadCount && data.recvMsgOpt !== 2 ?
                                    <div
                                        className="count">{ data.unreadCount > 99 ? "99+" : data.unreadCount }</div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MessageItem;
