import { DotLoading, SpinLoading } from "antd-mobile";

function Loading() {
    return <div className="my-loading">
        <div style={ { color: '#00b578' } }>
            <DotLoading color="currentColor"/>
            <span>Loading</span>
        </div>
    </div>;
}

function LoadingSpin() {
    return <div className="my-loading">
        <div style={ { color: '#00b578' } }>
            <SpinLoading color="#00b578"/>
        </div>
    </div>;
}
export {
    LoadingSpin,
    Loading
}
export default Loading;
