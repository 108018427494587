import backIcon from "@/assets/svg/back-arrow.svg";
import React, { useContext, useState } from "react";
import quotoIcon from "@/assets/svg/quote.svg";
import rubbishIcon from "@/assets/svg/rubbish.svg";
import { useAppDispatch, useAppSelector } from "@/store";
import { setQuoteMessage, setSelectState } from "@/store/slice/chat";
import { ImContext } from "@/openIm";
import { CenterPopup } from "antd-mobile";
import { toast } from "@/utils";
import classNames from "classnames";
import { MessageStatus } from "open-im-sdk";

function SelectNavbar() {
    const dispatch = useAppDispatch();
    const { isSelect, selectList, chatInfo } = useAppSelector(state => state.chat);
    const num = selectList.length;
    const [visible, setVisible] = useState(false);
    const { openIm } = useContext(ImContext);
    const closebar = () => {
        dispatch(setSelectState(false));
    };
    const chooseQuoteMessage = () => {
        const current = selectList[0];
        if (current.status !== MessageStatus.Succeed) {
            toast("Unable to reference unsent messages", 'error');
            return;
        }
        dispatch(setQuoteMessage(current));
        resetState();
    };
    // 删除本地的信息
    const deleteMessage = async (all: boolean) => {
        const selectIds = selectList.map(item => item.clientMsgID);
        // 是全部删除 还是删除本地
        await Promise.all(selectIds.map(async (clientMsgID) => openIm.deleteMessage(chatInfo.conversationID, clientMsgID, all)));
        // 提示完成
        toast("delete success!", "success");
        // 关闭弹框
        resetState();
    };
    // 重置当前组件状态
    const resetState = () => {
        dispatch(setSelectState(false));
        setVisible(false);
    };
    const showModal = () => {
        setVisible(true);
    };
    if (!isSelect) return null;
    return <div className="selectBar">
        <div className="selectBar-left">
            <img src={ backIcon } alt="" className="navbar-backIcon" onClick={ closebar }/>
            <div>{ num }</div>
        </div>
        <div className="selectBar-right">
            {
                num === 1 &&
                <img onClick={ chooseQuoteMessage } className="icon" src={ quotoIcon } alt=""/>
            }
            <img className="icon" src={ rubbishIcon } alt="" onClick={ showModal }/>
        </div>
        <CenterPopup
            visible={ visible }
            onMaskClick={ () => {
                setVisible(false);
            }
            }
            className="deleteModal"
        >
            <div className="title">{ `Delete ${ num > 1 ? selectList.length + "messages" : 'message' }?` }</div>
            <div className={ classNames("handler") }>
                <div className="btn" onClick={ () => deleteMessage(false) }>Delete for me</div>
                <div className="btn" onClick={ () => setVisible(false) }>cancel</div>
            </div>
        </CenterPopup>
    </div>;
}


export default SelectNavbar;
