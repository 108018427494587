import React from 'react';
import './chat.scss';
import NoticeBar from "@/pages/Chat/feature/NoticeBar";
import WinHistory from "@/pages/Chat/feature/WinHistory";
import ChatMain from "@/pages/Chat/feature/ChatMain";
import ChartFooter from "@/pages/Chat/feature/InputFoot";
import SelectNavbar from "@/pages/Chat/feature/SelectNavbar";
import useMarkRead from "@/pages/Chat/feature/hooks/useMarkRead";
import VoiceBox from "@/pages/Chat/feature/VoiceBox";
import { useAppSelector } from "@/store";
import FollowedModal from "@/pages/Chat/feature/Modal/FollowedModal";
import NoticeDetailModal from "@/pages/Chat/feature/Modal/NoticeDetailModal";
import RulesModal from "@/pages/Chat/feature/Modal/RulesModal";
import SettingModal from "@/pages/Chat/feature/Modal/SettingModal";
import PersonalModal from "@/pages/Chat/feature/Modal/PersonalModal";
import LoginModal from "@/components/LoginModal";
import TwiceModal from "@/components/TwiceModal";
import useInit from "@/pages/Chat/feature/hooks/useInit";
import EditorModal from "@/components/EditorModal";
import classNames from "classnames";

const Chat = () => {
    useMarkRead(1000); // 每隔1s标记已读
    useInit();
    const showVoice = useAppSelector(state => state.voice.boxVisible);
    const historyVisible = useAppSelector(state => state.chat.historyVisible);
    return (
        <div className={ classNames("chat-container", { showPadding: historyVisible }) }>
            <SelectNavbar/>
            <NoticeBar/>
            <WinHistory/>
            <ChatMain/>
            {
                showVoice ? <VoiceBox/> : <ChartFooter/>
            }

            <FollowedModal/>
            <NoticeDetailModal/>
            <RulesModal/>
            <SettingModal/>
            <PersonalModal/>
            <LoginModal/>
            <EditorModal/>
            <TwiceModal/>
        </div>
    );
};

export default Chat;
