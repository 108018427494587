import React, { useEffect, useState } from 'react';
import { Image, Swiper } from "antd-mobile";
import { useAppDispatch, useAppSelector } from "@/store";
import { getVmSize } from "@/utils";
import { getHistoryWin } from "@/apis/im";
import { setHistoryVisible } from "@/store/slice/chat";

const WinHistory = () => {
    const { noticeBarVisible, historyVisible } = useAppSelector(state => state.chat);
    const [list, setList] = useState<{
        winner: string;
        price: string;
        game: string
    }[]>([]);
    const dispatch = useAppDispatch();
    const showHistory = () => {
        dispatch(setHistoryVisible(true))
    }
    const closeHistory = () => {
        dispatch(setHistoryVisible(false))
    }
    useEffect(() => {
        getHistoryWin().then(res => {
            setList(res.data || []);
            showHistory();
        });
    }, []);
    if (!historyVisible) return <div className="win-history-close"
                                     style={ { top: noticeBarVisible ? getVmSize(40) : 0 } }>
        <Image placeholder=""
               onClick={ showHistory }
               className="win-history-close-icon"
               src={ require("@/assets/svg/historyClose.svg").default }></Image>
    </div>;
    return (
        <div className="win-history" style={ { top: noticeBarVisible ? getVmSize(40) : 0 } }>
            <Swiper
                direction="vertical"
                loop
                className="win-history-list"
                allowTouchMove={ false }
                autoplayInterval={ 1500 }
                autoplay
                indicator={ () => null }
                style={ { '--height': getVmSize(15) } }
            >
                {
                    list.map((item, index) => {
                        return <Swiper.Item key={ index }>
                            <div className="win-history-item">
                                { item.winner } won <span className="price">₹{ item.price }</span> in
                                the { item.game } game
                            </div>
                        </Swiper.Item>;
                    })
                }
            </Swiper>
            <Image placeholder="" onClick={ closeHistory } className="icon_close"
                   src={ require("@/assets/svg/historyClose.svg").default }></Image>
        </div>
    );
};

export default WinHistory;
