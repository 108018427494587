import "./chatGroupSetting.scss";
import { useSearchParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import imContext from "@/openIm/ImContext";
import { useAppDispatch, useAppSelector } from "@/store";
import { updateConversation, updateGroupDetail, updateGroupMember } from "@/store/slice/groupSetting";
import { ConversationType } from "@/store/slice/chat";
import Header from "@/pages/Chat/feature/Modal/SettingModal/Header";
import Card from "@/pages/Chat/feature/Modal/SettingModal/Card";
import Notification from "@/pages/Chat/feature/Modal/SettingModal/Notification";
import Setting from "@/pages/Chat/feature/Modal/SettingModal/Setting";
import Member from "@/pages/Chat/feature/Modal/SettingModal/Member";

function ChatGroupSetting() {
    const { openIm } = useContext(imContext);
    const visible = useAppSelector(state => state.chat.settingVisible);
    const [searchParams] = useSearchParams();
    const groupID = searchParams.get("groupID");
    const dispatch = useAppDispatch();
    // 获取会话信息
    const getConversationDetail = async (groupID: string) => {
        const conversation = await openIm.getOneConversation({
            sourceID: groupID,
            sessionType: ConversationType.Group,
        });
        dispatch(updateConversation(conversation));
    };
    // 获取群信息
    const getGroupDetail = async (groupID: string) => {
        const group = await openIm.getSpecifiedGroupsInfo(groupID);
        dispatch(updateGroupDetail(group));
    };
    // 获取群成员
    const getGroupMemberList = async (groupID: string) => {
        const members = await openIm.getGroupMemberList(groupID);
        dispatch(updateGroupMember(members));
    };
    const init = async () => {
        if (groupID) {
            getConversationDetail(groupID);
            getGroupDetail(groupID);
            getGroupMemberList(groupID);
        }
    };
    useEffect(() => {
        if (visible) {
            init();
        }
    }, [visible]);
    return <div className="chatGroupSetting">
        <Header/>
        <Card/>
        <Notification/>
        <Setting/>
        <Member/>
    </div>;
}

export default ChatGroupSetting;
