import { useEffect, useState } from "react";
import classNames from "classnames";
import { TypeName } from "@/utils";
import store2 from "store2";

type ReflexQuickComprehensiveProps = {
    type: TypeName;
    value: string | number;
}
const getColorList = (packageId: number | string) => {
    const list = [
        "#D57300FF",
        "#0087d4",
        "#d50000",
        "#67a519",
        "#9800f5",
    ];
    let d1Color = "";
    switch (+packageId) {
        case 3:
            list[4] = "#9800F5";
            d1Color = "#9800f5";
            break;
        case 5:
            list[4] = "#D90000";
            d1Color = "#D90000";
            break;
        default:
    }
    return {
        colorList: list,
        d1Color,
    };
};
const typex = [TypeName.x2, TypeName.x3, TypeName.x4];
// const typed = [TypeName.d1, TypeName.d2, TypeName.d3, TypeName.d4];
const matrixType = ["Odd", "Even", "Big", "Small"];

function isModeValue(type: TypeName, value: number | string): value is number {
    return [TypeName.FPC, TypeName.matrix].includes(type);
}

function ReflexQuickComprehensive(props: ReflexQuickComprehensiveProps) {
    const { type, value = "" } = props;
    const packageId = store2.get("packageId");
    const { colorList, d1Color } = getColorList(packageId);
    const [localList, setLocalList] = useState<{ color: string, text: string, type: string }[]>([]);
    const init = () => {
        if (isModeValue(type as TypeName, value)) {
            if (type === TypeName.matrix) {
                const index = (value - 1) % 4;
                const list = [
                    {
                        color: colorList[Math.floor(value / 4)],
                        text: matrixType[index],
                        type: "matrix"
                    }
                ];
                setLocalList(list);
            } else {
                const list = [
                    {
                        color: colorList[3],
                        text: value as any,
                        type: "matrix"
                    }
                ];
                setLocalList(list);
            }
        } else if (type === TypeName.d1) {
            setLocalList([
                {
                    color: d1Color,
                    text: value as any,
                    type: "normal"
                }
            ]);
        } else {
            const isX = typex.includes(type);
            const vList = value.split("");
            const len = vList.length;
            const colorLen = colorList.length - 1;
            const list = vList.map((item, index) => {
                return {
                    color: isX ? colorList[4] : colorList[colorLen - len + index],
                    text: item,
                    type: "normal"
                };
            });
            setLocalList(list);
        }
    };
    useEffect(() => {
        init();
    }, []);
    return <div className="reflex-container">
        <div className="comprehensive">
            {
                localList.map((item, i) => {
                    return <div className={ classNames(item.type === "matrix" ? "matrix-item" : "comprehensive-item",
                    ) } key={ i } style={ { background: item.color } }>
                        { item.text }
                    </div>;
                })
            }
        </div>
    </div>;
}

export default ReflexQuickComprehensive;
