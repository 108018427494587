import type { CardItemItem } from "@/utils";
import ReflexQuickComprehensive from "@/pages/Chat/feature/Reflex/ReflexQuickComprehensive";

type QuickComprehensiveProps = {
    data: CardItemItem[]
}
function QuickComprehensive(props: QuickComprehensiveProps) {
    const { data } = props;
    return <div className="dice-game">
        {
            data.map((item,i) => {
                return <div className="dice-game-item" key={i}>
                    <ReflexQuickComprehensive type={ item.modeID! } value={ item.number as string }/>
                    <div className="price">₹{ item.amount.toFixed(2) } </div>
                </div>;
            })
        }

    </div>;
}

export default QuickComprehensive;
