import { Image, Space } from "antd-mobile";
import type { GroupMemberItem } from "open-im-sdk";
import { GroupMemberRole } from "open-im-sdk";
import mutedIcon from "@/assets/svg/user-muted.svg";
import mutedActiveIcon from "@/assets/svg/user-muted-active.svg";
import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { copyText, getVmSize, toast } from "@/utils";
import { useAppDispatch, useAppSelector } from "@/store";
import { setUserLike } from "@/apis/im";
import {
    setBigVisible, setCancelMutedVisible,
    setMutedVisible,
    setUserLikeCount,
    setUserMyLike
} from "@/store/slice/personal";
import ToggleHeart from "@/pages/Chat/feature/Modal/PersonalModal/feature/ToggleHeart";


// 判断是否对当前用户进行禁言
function authMuted(user: GroupMemberItem | undefined, otherUser: GroupMemberItem) {
    if (user) {
        if (user.userID === otherUser.userID) {
            return false;
        }
        if (user.roleLevel === GroupMemberRole.Owner) {
            return true;
        }
        if (user.roleLevel === GroupMemberRole.Admin) {
            return otherUser.roleLevel === GroupMemberRole.Nomal;
        }
        return false;
    }
    return false;
}

export default function User() {
    const userState = useAppSelector(state => state.personal.user);
    const { currentUserID } = useAppSelector(state => state.chat.chatInfo);
    const { muted } = useAppSelector(state => state.personal.user);
    const owners = useAppSelector(state => state.chat.groupOwnerList);
    const [hasMutedAuth, setHasMutedAuth] = useState(false);
    const dispatch = useAppDispatch();
    const handleMuted = async () => {
        if (!muted) {
            dispatch(setMutedVisible(true));
        } else {
            dispatch(setCancelMutedVisible(true));
        }
    };
    const toggleLike = useCallback(() => {
        setUserLike({
            userID: userState.userID,
            state: userState.myLike
        }).then(res => {
            if (res.status === 500) {
                toast(res.message, "error");
                return;
            }
            dispatch(setUserLikeCount(res.data.likeCount));
            dispatch(setUserMyLike(res.data.myLike));
        });
    }, [userState]);
    useEffect(() => {
        const owner = owners.find(item => item.userID === currentUserID);
        setHasMutedAuth(authMuted(owner, userState));
    }, [owners, userState, currentUserID]);
    const copy = () => {
        copyText(userState.thirdID, () => toast("Copied successfully"));
    };
    return <div className="user">
        <Space align="center" style={ { "--gap-horizontal": getVmSize(16) } }>
            <div className="user-avatar" onClick={ () => dispatch(setBigVisible(true)) }>
                <Image src={ userState.faceURL } className="avatar-image"></Image>
                <Image src={ require(`@/assets/VIP/v${ userState.vipLevel || 0 }.webp`) } className="vip-image"></Image>
            </div>
            <div className="user-message">
                <div className="name">{ userState.nickname }</div>
                <div className="userId" onClick={ copy }>User ID:{ userState.thirdID }</div>
            </div>
        </Space>
        <Space align="center" style={ { "--gap-horizontal": getVmSize(8), marginLeft: "auto" } }>
            <div className="user-heart">
                <div className="heart-image">
                    <ToggleHeart state={ userState.myLike } onClick={ toggleLike }/>
                </div>
                <div className="num">{ userState.likeCount }</div>
            </div>
            {
                hasMutedAuth ? <div className={ classNames("user-muted", { active: muted }) } onClick={ handleMuted }>
                    <Image src={ muted ? mutedActiveIcon : mutedIcon } className="muted-icon"></Image>
                </div> : null
            }
        </Space>


    </div>;
}
