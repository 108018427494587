import { useEffect } from "react";
import { IMSDK } from "@/openIm"
export const enum TypeName {
    matrix = 1,
    d4,
    d3,
    d2,
    x4,
    x3,
    x2,
    d1,
    FPC
}

const MESSAGE_TYPE = "chat-room";
export type CardItemItem = {
    number: string; // 骰子内容（转换成相应图片）
    amount: number; // 下注金额 （需要加上货币符号）
    awardAmount?: number; // 赢钱金额，（直接展示即可，如果没有该值，则未开奖）
    playType?: string; // 游戏类型，（直接展示即可）
    modeID?: TypeName;
    type?: string;
    tabName?: string;
    modeName?: string;
    status: 0 | 1 | 2;
    digits?: string;
    count?: number
}

export const enum EventType {
    PLAY = "play",
    FOLLOWED = "followed",
    SUCCESS = "success",
    FAIL = "fail",
    LOGIN = "login",
    BACK = "back",
    USER_EDITOR = "userEditor",
    START_GAME = "startGame"
}
// 公用的
type MessageBase = {
    message?: string;
}

interface MessageSend extends MessageBase {
    gameType: string; // 游戏类型
    rawData: any; // 每个游戏的原有数据
    list: CardItemItem[]; // 转化后的数据
    issNo: string; // 期号
    currentUserID: string; // 自己的id
    gameId: number;
}

export type MessageDataPayLoad = {
    event: EventType, // 通信的事件类型
    data: Partial<MessageSend>
}
export type MessageData = {
    source: string, // message的来源
    // 主要数据
    payload: MessageDataPayLoad
}

export function sendPostMessage(payload: MessageData["payload"]) {
    const options = {
        source: MESSAGE_TYPE,
        payload
    };
    window.parent.postMessage(options as MessageData, "*");
}

export function sendBackMessage() {
    IMSDK.logout();
    sendPostMessage({
        event: EventType.BACK,
        data: {
            message: "back"
        }
    });
}

export function sendUserEditorMessage() {
    sendPostMessage({
        event: EventType.USER_EDITOR,
        data: {
            message: EventType.USER_EDITOR
        }
    });
}

export function sendLoginMessage() {
    IMSDK.logout();
    sendPostMessage({
        event: EventType.LOGIN,
        data: {
            message: "login"
        }
    });
}

export function sendStartGameMessage(id: number) {
    sendPostMessage({
        event: EventType.START_GAME,
        data: {
            gameId: id,
            message: EventType.START_GAME
        }
    });
}

export function useListenMessage(onMessage: (data: MessageDataPayLoad) => void) {
    useEffect(() => {
        function handleMessage(event: MessageEvent<MessageData>) {
            const responseData = event.data;
            if (responseData.source && responseData.source === MESSAGE_TYPE) {
                onMessage(responseData.payload);
            }
        }

        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);
}
