import React from 'react';
import ReactDOM from 'react-dom/client';
import VConsole from 'vconsole';
import "@/assets/fonts/font.css";
import 'normalize.css';
import 'antd-mobile/bundle/css-vars-patch.css'; // 兼容ios9
import App from './App';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === "development") {
    new VConsole();
}
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <App/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
