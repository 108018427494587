import { Popup } from "antd-mobile";
import React, { memo } from "react";
import ChatGroupSetting from "./ChatGroupSetting";
import { useAppSelector } from "@/store";


function SettingModal() {

    const visible = useAppSelector(state => state.chat.settingVisible);
    return <Popup visible={ visible } position={ "right" }>
        <div style={ { width: "100vw", overflowY: "auto", height: "100vh" } } id="setting-modal">
            <ChatGroupSetting></ChatGroupSetting>
        </div>
    </Popup>;
}

export default memo(SettingModal);
