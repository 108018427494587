import { useContext, useMemo } from "react";
import SettingModal from "@/components/SettingModal";
import { useAppDispatch, useAppSelector } from "@/store";
import { getUserByGroupId, setCancelMutedVisible, setMuted } from "@/store/slice/personal";
import { toast } from "@/utils";
import { ImContext } from "@/openIm";
import { useCountDown } from "@/hooks/useCountDown";

function CancelMuteModal() {
    const { groupID } = useAppSelector(state => state.chat.chatInfo);
    const { cancelMutedVisible, user, userId } = useAppSelector(state => state.personal);
    const { openIm } = useContext(ImContext);
    const dispatch = useAppDispatch();
    const { days, seconds, hours, minutes } = useCountDown(user.muteEndTime, () => {
        dispatch(setCancelMutedVisible(false));
        dispatch(setMuted(false));
    });
    const cancel = () => {
        reset();
    };
    const onConfirm = () => {
        openIm.muteGroupMember(groupID, userId, 0).then(res => {
            dispatch(getUserByGroupId());
            toast("You have muted this user");
            reset();
        });
    };
    const reset = () => {
        dispatch(setCancelMutedVisible(false));
    };
    const title = useMemo(() => {
        return `Unmute ${ user.nickname }?`;
    }, [user]);
    const remindTime = useMemo(() => {
        if (+days <= 0) {
            return `${ hours }:${ minutes }:${ seconds }`;
        }
        return `${ days } days ${ hours }:${ minutes }:${ seconds }`;
    }, [days, seconds, hours, seconds]);
    return <SettingModal
        title={ title }
        visible={ cancelMutedVisible }
        cancel={ cancel }
        confirm={ onConfirm }
    >
        <div>
            Remaining: <span
            style={ { color: "#F15802" } }>{ remindTime }</span>
        </div>
    </SettingModal>;
}

export default CancelMuteModal;
