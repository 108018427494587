import { Image } from "antd-mobile";
import Switch from "@/components/Switch";
import muteNotificationsIcon from "@/assets/svg/muted.svg";
import { MessageReceiveOptType } from "open-im-sdk";
import toast from "@/components/Toast";
import topIcon from "@/assets/svg/top.svg";
import { useAppDispatch, useAppSelector } from "@/store";
import { useContext } from "react";
import imContext from "@/openIm/ImContext";
import { useParams, useSearchParams } from "react-router-dom";
import { ConversationType } from "@/store/slice/chat";
import { updateConversation } from "@/store/slice/groupSetting";
import { fetchConversationList } from "@/store/slice/home";

export default function Setting() {
    const dispatch = useAppDispatch();
    const { openIm } = useContext(imContext);
    const [searchParams] = useSearchParams();
    const groupID = searchParams.get("groupID");
    const { conversationID } = useParams();
    const { conversationDetail } = useAppSelector(state => state.groupSetting);
    // 获取会话信息
    const getConversationDetail = async (groupID: string) => {
        const conversation = await openIm.getOneConversation({
            sourceID: groupID,
            sessionType: ConversationType.Group,
        });
        dispatch(updateConversation(conversation));
    };
    const settingList = [
        {
            title: "Mute notifications", // 免打扰
            icon: muteNotificationsIcon,
            type: "mute",
            checked: !!conversationDetail.recvMsgOpt, // 0 正常接受 1保留字段 2 接受消息，不计入总未读
            async onClick(val: boolean) {
                await openIm.setRecvMessageOpt(conversationID!, val ? MessageReceiveOptType.NotNotify : MessageReceiveOptType.Nomal);
                getConversationDetail(groupID!);
                toast.show("success!");
            }
        },
        {
            title: "Top group",
            icon: topIcon,
            type: "pinned",
            checked: conversationDetail.isPinned,
            async onClick(val: boolean) {
                if (conversationID) {
                    await openIm.setPinConversation(conversationID, val);
                    await getConversationDetail(groupID!);
                    dispatch(fetchConversationList())
                    toast.show("success!");
                }
            }
        },
    ];
    return <div className="group-setting">
        {
            settingList.map(item => {
                return <div className="group-setting-item" key={ item.type }>
                    <Image className="icon" src={ item.icon }></Image>
                    <div className="group-setting-item-text">{ item.title }</div>
                    <div className="checkedBox">
                        <Switch checked={ item.checked } onChange={ e => {
                            item.onClick(!item.checked);
                        } }></Switch>
                    </div>
                </div>;
            })
        }

    </div>;
}
