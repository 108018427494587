import { CardTypeData } from "@/pages/Chat/feature/ShareCards";
import { MessageItem } from "open-im-sdk/lib/types/entity";
import { authLogin, EventType, sendPostMessage } from "@/utils";
import { useMemo } from "react";

type LuckMessageProps = {
    self: boolean;
    data: MessageItem;
    cardData: CardTypeData
}

function LuckMessage(props: LuckMessageProps) {
    const { cardData, self } = props;
    const playnow = () => {
        if (!authLogin()) return;
        sendPostMessage({
            event: EventType.PLAY,
            data: {
                ...cardData
            }
        });
    };
    const renderAmount = useMemo(() => {
        return `${ cardData.totalAmount.toFixed(2) }${ cardData.count ? '*' + cardData.count : '' }`;
    }, [cardData.count]);
    return (
        <>
            <div className="luck-message">
                <div className="luck-message-title">
                    <img className="icon" src={ require('@/assets/luck-icon.png') } alt=""/>
                    <span>I'm luck today!</span>
                </div>
                <div className="luck-message-box">
                    <img className="luck-message-box-icon" src={ cardData.gameIconUrl } alt=""/>
                    <div className="luck-content">
                        <div className="luck-content-title">
                            { cardData.gameName }
                        </div>
                        <div className="luck-content-price">₹{ renderAmount }</div>
                    </div>
                    <div className="luck-won">
                        <div className="won-text">Won</div>
                        <div className="won-price">₹{ cardData.totalAwardAmount?.toFixed(2) || 0.00 }</div>
                    </div>
                </div>
                {
                    !self && <div className="luck-message-btn">
                        <div className="btn" onClick={ playnow }>
                            <span>Play</span>
                            <span>Now</span>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}


export default LuckMessage;
