import uncheckedIcon from "./assets/unchecked.svg";
import checkedIcon from "./assets/checked.svg";
import { useEffect, useState } from "react";

type SwitchType = {
    checked: boolean;
    onChange?: (checked: boolean) => void;
}
export default function Switch(props: SwitchType) {
    const { checked, onChange } = props;
    const [localChecked, setLocalChecked] = useState(checked);
    useEffect(() => {
        setLocalChecked(checked);
    }, [checked]);
    const handlerClick = () => {
        onChange?.(!localChecked);
    };
    return <div onClick={ handlerClick } className="my-switch">
        <img src={ checked ? checkedIcon : uncheckedIcon } alt=""/>
    </div>;
}
