import React, { useEffect, useRef, useState } from "react";
import OpenIm from "@/openIm";
import store2 from "store2";
import { getImToken } from "@/apis/app";
import { toast } from "@/utils";
import store from "@/store";
import { fetchConversationList, fetchTotalUnread } from "@/store/slice/home";
import { updateCommonUser } from "@/store/slice/common";

function useLogin() {
    const [hasLogin, setHasLogin] = React.useState(false);
    const [errPage, setErrPage] = useState(false);
    const openIm = useRef<OpenIm>();
    const getSearchParams = (type: string) => {
        const search = window.location.href.split("?")[1];
        const searchParams = new URLSearchParams(search);
        return searchParams.get(type) || '';
    };
    // 1游客 删除当前的本地origintoken信息，使用deviceCode获取imtoken
    // 2用户 使用自己的origintoken获取imtoken
    const getVisitorState = (data: string): boolean => {
        if (data) {
            return data === "true";
        }
        return store2.get("isVisitor");
    };
    const getUserToken = async () => {
        const originToken = getSearchParams("originToken") || store2.get("originToken");
        const visitor = getSearchParams("isVisitor");
        const deviceCode = getSearchParams("deviceCode") || store2.get("deviceCode");
        const packageId = getSearchParams("packageId") || store2.get("packageId");
        const isVisitor = getVisitorState(visitor);
        store2.set("packageId", parseInt(packageId) || 3);
        try {
            let res;
            if (isVisitor) {
                // 1. 游客状态
                // 3. 登录im
                // 清除老的token
                store2.remove("originToken");
                // 2. 通过deviceCode获取imtoken
                res = await getImToken(deviceCode);
            } else {
                // 1.不是游客，读取origintoken，没有的话从内存获取
                store2.set("originToken", originToken);
                // 2. 获取
                res = await getImToken();
            }
            if (res.code === 0) {
                return {
                    originToken,
                    isVisitor,
                    deviceCode,
                    imToken: res.data.imToken,
                    userID: res.data.userID,
                    chatToken: res.data.chatToken
                };
            }
            return Promise.reject(res);
        } catch (err) {
            return Promise.reject(err);
        }

    };
    useEffect(() => {
        openIm.current = new OpenIm({
            afterLogin: (data) => {
                if (data) {
                    setHasLogin(true);
                    store.dispatch(fetchConversationList());
                    store.dispatch(fetchTotalUnread());
                    store.dispatch(updateCommonUser(data));
                } else {
                    toast("the server is busy", "error");
                }
            },
            store
        });
        getUserToken().then(res => {
            store2.setAll(res);
            // 登录im
            openIm.current?.login(res);
        }).catch(err => {
            setErrPage(true);
            toast(err.msg || "something went wrong", "error");
        });

    }, []);
    return {
        errPage,
        hasLogin,
        openIm
    };
}

export default useLogin;
