import { CenterPopup } from "antd-mobile";
import React from "react";
import "./EditorModal.scss";
import { useAppDispatch, useAppSelector } from "@/store";
import { setEditorModalVisible } from "@/store/slice/common";
import { sendUserEditorMessage } from "@/utils";

function EditorModal() {
    const visible = useAppSelector(state => state.common.editorModalVisible);
    const dispatch = useAppDispatch();
    const cancel = () => {
        dispatch(setEditorModalVisible(false));
    };
    return <CenterPopup
        visible={ visible }
        onMaskClick={ cancel }
        getContainer={() => document.body}
    >
        <div className="editorModal">
            <div className="title">Improve personal data</div>
            <div className="content">Personal information is required to enter the chat room</div>
            <div className="handler">
                <div className="btn" onClick={ sendUserEditorMessage }>To Reset</div>
                <div className="btn" onClick={ cancel }>Cancel</div>
            </div>
        </div>

    </CenterPopup>;
}

export default EditorModal;
