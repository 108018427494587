import React from "react";
import { MessageItem } from "open-im-sdk/lib/types/entity";
import MessageState from "@/pages/Chat/feature/MessageItem/MessageState";

type TextMessageProps = {
    data: MessageItem
}
export default function TextMesssage(props: TextMessageProps) {
    const { data } = props;
    return <div>
        <span style={ { whiteSpace: "pre-wrap" } }>
        { data.textElem.content }
        </span>
        <MessageState data={ data } style={ { float: "right" } }></MessageState>
    </div>;
}
