import { WaveSurferOptions } from "wavesurfer.js";

const config = {
    waveColor: "#C2C9CC",
    progressColor: "#66777F",
    backend: "WebAudio",
    barHeight: 2,
    barWidth: 3,
    barGap: 3,
    barRadius: 6,
    interact: false,
} as WaveSurferOptions;

export default config;
