import { Ellipsis, Image, Space } from "antd-mobile";
import React from "react";
import classNames from "classnames";
import { MessageType } from "open-im-sdk";
import { useAppDispatch, useAppSelector } from "@/store";
import closeIcon from "@/assets/svg/close-grey.svg";
import cardIcon from "@/assets/svg/customCard.svg";
import { setQuoteMessage } from "@/store/slice/chat";
import voiceIcon from "@/assets/svg/voice-message.svg";
import picIcon from "@/assets/svg/photo.svg";
import { formattedTime } from "@/utils/utils";

function Quote() {
    const { quoteMessage, chatInfo } = useAppSelector(state => state.chat);
    const dispatch = useAppDispatch();
    if (!quoteMessage) return null;
    const isSelf = chatInfo.currentUserID === quoteMessage.sendID;

    const renderContent = () => {
        switch (quoteMessage.contentType) {
            // 文本
            case MessageType.TextMessage:
                return <Ellipsis content={ quoteMessage.textElem.content } rows={ 3 }></Ellipsis>;
            case MessageType.QuoteMessage:
                return <Ellipsis content={ quoteMessage.quoteElem.text } rows={ 3 }></Ellipsis>;
            case MessageType.VoiceMessage:
                const time = formattedTime(quoteMessage.soundElem.duration * 1000).join(":");
                return <Space>
                    <Image className="icon" src={ voiceIcon } alt="" width={ 12 } height={ 16 }/>
                    <span>Voice Message ({ time })</span>
                </Space>;
            case MessageType.CustomMessage:
                const { type } = JSON.parse(quoteMessage.customElem.data)
                if (type === "image") {
                    return <Space>
                        <Image src={ picIcon }></Image>
                        <span>GIF</span>
                    </Space>;
                }
                return <Space>
                    <Image src={ cardIcon }></Image>
                    <span>Bet Slip</span>
                </Space>;
            default:
                return "未定义的类型：" + quoteMessage.contentType;
        }
    };
    return <div className={ classNames("quote-message", { isSelf }) }>
        <div className={ "quote-message-name" }>
            <div className="text">
                {
                    isSelf ? "YOU" : quoteMessage.senderNickname
                }
            </div>
            <img src={ closeIcon } className="close" onClick={ () => {
                dispatch(setQuoteMessage(null));
            } } alt=""/>
        </div>
        <div className="quote-message-content">
            {
                renderContent()
            }
        </div>
    </div>;
}

export default Quote;
