import lottie, { AnimationItem } from "lottie-web";
import heartLottie from "@/assets/lottie/heart.json";
import { useEffect, useState, memo, useRef } from "react";

type ToggleHeartProps = {
    onClick?: () => void;
    state?: boolean;
};

function ToggleHeart(props: ToggleHeartProps) {
    const { state, onClick } = props;
    const lottieref = useRef<any>();
    const animation = useRef<AnimationItem>();
    const [totalFrame, setTotalFrame] = useState(0);

    const clickHandler = () => {
        if (animation.current) {
            if (state) {
                animation.current.play();
            } else {
                animation.current?.goToAndStop(totalFrame);
            }
        }

    };
    useEffect(() => {
        const ani = lottie.loadAnimation({
            container: lottieref.current,
            animationData: heartLottie,
            renderer: "svg",
            loop: false,
            autoplay: false,
        });
        animation.current = ani;
        setTotalFrame(ani.totalFrames);
        return () => {
            ani.destroy();
        };
    }, []);
    useEffect(() => {
        clickHandler();
    }, [state]);
    return <div style={ { width: "100%", height: "100%" } } onClick={ onClick } ref={ lottieref }>
    </div>;
}

export default memo(ToggleHeart);
