// 系统提示
import React from 'react';
import { ChatMessageItem } from "@/store/slice/chat";
import { MessageType } from "open-im-sdk";
import store from "@/store";
import type { PublicUserItem } from "open-im-sdk/lib/types/entity";
import { getVmSize } from "@/utils";

const getName = (user: PublicUserItem) => {
    const state = store.getState();
    const chatInfo = state.chat.chatInfo;
    return chatInfo.currentUserID === user.userID ? "YOU" : user.nickname;
};
type SystemNotificationProps = {
    data: ChatMessageItem
}
const getText = (data: ChatMessageItem) => {
    if (data.contentType === "localTime" as any) return data.localTime;
    const detail = JSON.parse(data.notificationElem.detail);
    const opUser = detail.opUser;
    switch (data.contentType) {
        case MessageType.GroupCancelMuted:
            return `${ getName(opUser) }all bans closed`;
        case MessageType.GroupMuted:
            return `${ getName(opUser) }all bans enabled`;
        case MessageType.MemberInvited:
            const inviteDetails = JSON.parse(data.notificationElem.detail);
            const inviteOpUser = inviteDetails.opUser;
            const invitedUserList = inviteDetails.invitedUserList ?? [];
            let inviteStr = "";
            invitedUserList.find(
                (user: any, idx: number) =>
                    (inviteStr += `${ getName(user) } `) && idx > 3
            );
            const operator = getName(inviteOpUser);
            const invitedUser = `${ inviteStr }${ invitedUserList.length > 3 ? "..." : "" }`;
            return `${ invitedUser } join the group chat`;
        // return `${ operator } invited ${ invitedUser } to join the group chat`;
        case MessageType.GroupCreated:
            return `${ getName(opUser) } created the group chat`;
        case MessageType.GroupOwnerTransferred:
            const transferOpUser = detail.newGroupOwner;
            return `${ getName(opUser) } transferred the group owner to ${ getName(transferOpUser) }`;
        case MessageType.GroupDismissed:
            return `${ getName(opUser) } dismissed the group chat`;
        case MessageType.GroupMemberCancelMuted:
            return `${ getName(opUser) } unbanned ${ getName(detail.mutedUser) }`;
        case MessageType.GroupMemberMuted:
            return `${ getName(detail.mutedUser) } wa banned by ${ getName(opUser) } `;
        case MessageType.GroupNameUpdated:
            return `${ getName(opUser) } changed the group name to ${ detail.group.groupName }`;
        case MessageType.GroupAnnouncementUpdated:
            return `${ getName(opUser) } modified group announcement`;
        default:
            return data.contentType;
    }
};

function SystemNotification(props: SystemNotificationProps) {
    const { data } = props;
    return <div style={ { display: "flex", justifyContent: "center", alignItems: "center", margin: "8px 12px" } }>
        <span style={ {
            background: "#fff",
            color: "#667781",
            borderRadius: "5px",
            padding: "4px 8px",
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            fontSize: getVmSize(12)
        } }>
            { getText(data) }
        </span>
    </div>;
}


export default SystemNotification;
