import { Image } from "antd-mobile";
import backIcon from "@/assets/svg/back-arrow.svg";
import { setSettingVisible } from "@/store/slice/chat";
import defaultChat from "@/assets/svg/defalt-chat.svg";
import dot from "@/assets/svg/dot.svg";
import { useAppDispatch, useAppSelector } from "@/store";

export default function Card() {
    const dispatch = useAppDispatch();
    const { groupDetail, memberList } = useAppSelector(state => state.groupSetting);
    return <div className="group-card">
        <Image src={ backIcon } onClick={ () => dispatch(setSettingVisible(false)) } className="back-icon"></Image>
        <Image src={ groupDetail?.faceURL } fallback={ <Image src={ defaultChat }></Image> }
               className="image"></Image>
        <div className="group-card-title">{ groupDetail?.groupName }</div>
        <div className="group-card-memeber">
            <span>Group</span>
            <Image style={ { margin: "0 8px" } }
                   src={ dot }
                   width={ 2 }
                   height={ 2 }
            />
            <span>{ memberList.length }</span>
            <span style={ { textIndent: '0.2em' } }>Participants</span>
        </div>
    </div>;
}
