import "./home.scss";
import MessageList from "@/pages/Home/features/MessageList";
import WelcomeModal from "@/pages/Home/features/WelcomeModal";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "@/store";

function Home() {
    const homeStore = useAppSelector(state => state.home);
    const { list, goChatFirst, oneChat } = homeStore;
    const renderHtml = () => {
        if (list && list.length > 1 && !goChatFirst) return <>
            <div className="home">
                <MessageList></MessageList>
            </div>
            <WelcomeModal/>
        </>;
        if (oneChat.conversationID) {
            return <Navigate
                to={ `/chat/${ oneChat.conversationID }?groupID=${ oneChat.groupID }&userID=` }></Navigate>;
        } else {
            return null;
        }

    };
    return (
        <>
            {
                renderHtml()
            }
        </>
    );
}

export default Home;

