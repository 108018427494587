import { getBaseUrlById, type TypeResponse } from "@/apis/http";
import store2 from "store2";

const apis = {
    getReqParams: "/openIm/getReqParams", // 获取imtoken
    getUserStatistics: "/user/getUserStatistics" // 获取当前用户最近游戏信息
};

type ImTokenRes = {
    userID: string;
    chatToken: string;
    imToken: string;
}
// 获取imtoken
export const getImToken = (deviceCode?: string) => {
    const http = getBaseUrlById(store2.get("packageId"));
    const headers = deviceCode ? {
        deviceCode
    } : {};
    return http.post<any, TypeResponse<ImTokenRes>>(apis.getReqParams, null, {
        headers
    });
};

// 获取当前用户最近的游戏列表
export const getUserLastGame = (params: { imUserId: string }) => {
    const http = getBaseUrlById(store2.get("packageId"));
    return http.post<any, TypeResponse<any>>(apis.getUserStatistics, params);
};

