import { Image } from "antd-mobile";
import backIcon from "@/assets/svg/back-arrow.svg";
// import closeIcon from "@/assets/svg/close-grey.svg";
import { useAppDispatch } from "@/store";
import { closePersonVisible } from "@/store/slice/personal";
import defaultIcon from "@/assets/svg/default-person.svg";

export default function Header() {
    const dispatch = useAppDispatch();
    return <div className="header">
        <div className="header-left">
            <Image className="header-back" src={ backIcon } fallback={ <Image src={ defaultIcon }/> }
                   onClick={ () => dispatch(closePersonVisible()) }></Image>
        </div>
        <div className="header-right">
            {/*<Image className="header-close" src={ closeIcon } onClick={ () => dispatch(closePersonVisible()) }></Image>*/ }
        </div>
    </div>;
}
