import { useParams } from "react-router-dom";
import { useCallback, useContext, useEffect } from "react";
import imContext from "@/openIm/ImContext";
import { useAppSelector } from "@/store";
import { ConversationType } from "@/store/slice/chat";

function useMarkRead(delay = 2000) {
    const params = useParams();
    const { openIm } = useContext(imContext);
    const chatInfo = useAppSelector(state => state.chat.chatInfo);
    const { conversationID = "" } = params;
    const markRead = useCallback(() => {
        const sourceID = chatInfo.conversationType === ConversationType.Single ? chatInfo.userID : chatInfo.groupID;
        //判断是否存在未读信息
        openIm.markConversationAsRead({
            sourceID,
            sessionType: chatInfo.conversationType,
            conversationID
        });
    }, [conversationID, chatInfo, openIm]);
    useEffect(() => {
        const timer = setInterval(markRead, delay);
        return () => {
            clearInterval(timer);
        };
    }, [chatInfo, markRead, conversationID, delay]);
}

export default useMarkRead;
