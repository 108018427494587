import React from 'react';
import { Outlet } from "react-router-dom";
import { ImContext } from "@/openIm";
import useLogin from "@/hooks/useLogin";
import { ErrorBlock } from "antd-mobile";

function Content() {
    const { hasLogin, errPage, openIm } = useLogin();
    return (
        <section className="layout-content">
            {
                hasLogin ?
                    <ImContext.Provider value={ { openIm: openIm.current! } }>
                        <Outlet/>
                    </ImContext.Provider>
                    : errPage ? <ErrorBlock fullPage></ErrorBlock> : <></>
            }
        </section>
    );
}

export default Content;
