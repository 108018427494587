import { Image } from "antd-mobile";
import defaultPerson from "@/assets/svg/default-person.svg";
import { useAppDispatch, useAppSelector } from "@/store";
import React from "react";
import { authLogin } from "@/utils";
import { openPersonVisible } from "@/store/slice/personal";
import { GroupMemberItem } from "open-im-sdk/lib/types/entity";
import { GroupMemberRole } from "open-im-sdk";

export const renderMemberRole = (roleLevel: GroupMemberRole, marginLeft = "auto") => {
    switch (roleLevel) {
        case GroupMemberRole.Owner:
            return <div className="role-tag" style={ { color: "#215F41", background:"#D8FDD2", marginLeft } }>
                Super Admin
            </div>;
        case GroupMemberRole.Admin:
            return <div className="role-tag" style={ { color: "#F15802", background:"#FDE4D2", marginLeft } }>
                Group Admin
            </div>;
        default:
            return null;
    }
};
export default function Member() {
    const { memberList } = useAppSelector(state => state.groupSetting);
    const dispatch = useAppDispatch();
    const handleClick = (item: GroupMemberItem) => {
        if (authLogin()) {
            dispatch(openPersonVisible({ userId: item.userID, groupId: item.groupID }));
        }
    };
    return <div className="group-member">

        <div className="group-member-value">
            <div className="group-member-value_bg"></div>
            <div className="text">{ memberList.length } participants</div>
        </div>
        <div className="group-member-list">
            {
                memberList.slice(0, 200).map(item => {
                    return <div className="group-member-item" key={ item.userID } onClick={ () => handleClick(item) }>
                        <Image src={ item.faceURL } className="avatar"
                               fallback={ <Image src={ defaultPerson }></Image> }></Image>
                        <div className="name">{ item.nickname }</div>
                        {
                            renderMemberRole(item.roleLevel)
                        }
                    </div>;
                })
            }
            {
                memberList.length > 200 && <div className="max-text">Maximum display of 200 members</div>
            }
        </div>
    </div>;
}
