import React, { ReactNode } from 'react';
import backIcon from "@/assets/svg/back-arrow.svg";
import rulesIcon from "@/assets/svg/rules.svg";
import dotsIcon from "@/assets/svg/dots.svg";
import closeIcon from "@/assets/svg/close-navbar.svg";
import './style.scss';
import { Image } from "antd-mobile";
import { setRuleModalVisible, setSettingVisible } from "@/store/slice/chat";
import { useAppDispatch } from "@/store";
import { useMatch } from "react-router-dom";
import { sendBackMessage } from "@/utils";

type NavbarProps = {
    title: ReactNode;
    onBack?: () => void;
    badge?: number;
    showBack?: boolean;
};
const Navbar = (props: NavbarProps) => {
    const dispatch = useAppDispatch();
    const isGroup = useMatch("/chat/:conversationID");
    const renderHandler = () => {
        return <>
            <img src={ rulesIcon } alt="" className="handler-icon" onClick={ () => {
                dispatch(setRuleModalVisible(true));
            } }/>
            <img src={ dotsIcon } alt="" className="handler-icon" onClick={ () => {
                dispatch(setSettingVisible(true));
            } }/>
        </>;
    };
    return (
        <div className="navbar">
            <div className="navbar-left">
                {
                    isGroup ? <Image placeholder="" src={ backIcon } alt="" className="navbar-backIcon"
                                     onClick={ props.onBack }/> : null
                }


                <div>{ props.title }</div>
                {
                    props.badge ? <div className="navbar-badge">{ props.badge > 99 ? '99+' : props.badge }</div> : null
                }
            </div>
            <div className="navbar-handler">
                {
                    isGroup ? renderHandler() :
                        <img src={ closeIcon } className="handler-icon" alt="" onClick={ sendBackMessage }/>
                }
            </div>


        </div>
    );
};

export default Navbar;
