import { useSearchParams } from "react-router-dom";
import store2 from "store2";

// 获取querystring的数据，如果没有从缓存中取
export function useQueryString(searchList: string[]) {
    const [search] = useSearchParams();
    const list = searchList.map(item => {
        return search.get(item) || store2.get(item);
    });
    return list;
}
