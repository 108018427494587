import backIcon from "@/assets/svg/back-arrow.svg";
import React, { useContext, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import { ImContext } from "@/openIm";
import disturbingIcon from "@/assets/svg/disturbing.svg";
import disturbingOffIcon from "@/assets/svg/disturbing-off.svg";
import topGroupIcon from "@/assets/svg/top-group.svg";
import topGroupOffIcon from "@/assets/svg/top-group-off.svg";
import { fetchConversationList, resetSetting } from "@/store/slice/home";
import { MessageReceiveOptType } from "open-im-sdk";
import { toast } from "@/utils";

function SelectNavbar() {
    const dispatch = useAppDispatch();
    const { setting, selectList } = useAppSelector(state => state.home);
    const num = selectList.length;
    const { openIm } = useContext(ImContext);
    const showPinned = useMemo(() => {
        return !!selectList.find(item => !item.isPinned);
    }, [selectList]);
    const showNotRecved = useMemo(() => {
        return !!selectList.find(item => item.recvMsgOpt === MessageReceiveOptType.Nomal); //  0 正常接受 1保留字段 2 接受消息，不计入总未读
    }, [selectList]);
    const closebar = () => {
        resetState()
    };
    // 重置当前组件状态
    const resetState = () => {
        dispatch(resetSetting());
    };
    const handleTop = async () => {
        const promises = selectList.map(item => {
            return openIm.setPinConversation(item.conversationID!, showPinned);
        });
        await Promise.all(promises);
        dispatch(fetchConversationList());
        resetState();
        toast("success");

    };
    const handleMuted = async () => {
        const promises = selectList.map(item => {
            return openIm.setRecvMessageOpt(item.conversationID!, showNotRecved ? MessageReceiveOptType.NotNotify : MessageReceiveOptType.Nomal);
        });
        await Promise.all(promises);
        dispatch(fetchConversationList());
        resetState();
        toast("success");
    };

    return !setting ? null : <div className="selectBar">
        <div className="selectBar-left">
            <img src={ backIcon } alt="" className="navbar-backIcon" onClick={ closebar }/>
            <div>{ num }</div>
        </div>
        <div className="selectBar-right">
            <img onClick={ handleTop } className="icon" src={ showPinned ? topGroupIcon : topGroupOffIcon } alt=""/>
            <img className="icon" src={ showNotRecved ? disturbingOffIcon : disturbingIcon } alt=""
                 onClick={ handleMuted }/>
        </div>
    </div>;
}


export default SelectNavbar;
