import { createHttp, TOKEN_TYPE, type TypeResponse } from "./http";

const http = createHttp(process.env.REACT_APP_IM_OTHER_URL!, TOKEN_TYPE.CHAT);
const apis = {
    history: "/group/prize/history",
    userFull: "/user/find/full",
    like: "/user/like",
    cancelLike: "/user/like/cancel",
    // 获取gif
    getGif: "/gif/list"
};

// 获取历史获胜接口
export const getHistoryWin = () => {
    return http.post<any, TypeResponse<any>>(apis.history);
};
// 获取当前用户信息 /user/find/full
export const getUserFull = (params: { userId: string }) => {
    const { userId } = params;
    return http.post<any, TypeResponse<any>>(apis.userFull, {
        userIDs: [userId]
    });
};

// 点赞
export const setUserLike = (params: { userID: string, state: boolean }) => {
    const { userID, state } = params;
    return http.post<any, TypeResponse<any>>(state ? apis.cancelLike : apis.like, { userID });
};
// 获取gif图片
export const getGifImage = (params: { pageNumber: number, showNumber: number }) => {
    return http.post<any, TypeResponse<any>>(apis.getGif, {
        pagination: params
    });
};

