import Header from "./Header";
import Content from "./content";
import './layout.scss';
import CircleLoading from "@/components/Loading/Circle";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import { IMSDK } from "@/openIm";
import SettingModal from "@/components/SettingModal";
import { setExpiredVisible, setKickVisible } from "@/store/slice/common";
import { sendLoginMessage } from "@/utils";


export default function Layout() {
    const loading = useAppSelector(state => state.common.loading);
    const { kickVisible, expiredVisible } = useAppSelector(state => state.common);
    const dispatch = useAppDispatch();
    useEffect(() => {
        window.addEventListener("beforeunload", (e) => {
            IMSDK.logout();
        });
    }, []);
    return <div className="layout">
        <Header></Header>
        <Content></Content>
        {
            loading ? <CircleLoading/> : null
        }
        <SettingModal title="Friendly reminder" visible={ kickVisible } cancel={ () => {
            dispatch(setKickVisible(false));
        } } confirm={ sendLoginMessage }>
            Your account has logged in on another device!
        </SettingModal>
        <SettingModal title="Friendly reminder" visible={ expiredVisible } cancel={ () => {
            dispatch(setExpiredVisible(false));
        } } confirm={ sendLoginMessage }>
            Current login has expired, please log in again!
        </SettingModal>
    </div>;
}
