import React from "react";
import ReflexColor from "@/pages/Chat/feature/Reflex/ReflexColor";
import type { CardItemItem } from "@/utils";

type ColorProps = {
    data: CardItemItem[]
}

function Color(props: ColorProps) {
    const { data } = props;
    return <div className="color-game">
        {
            data.map((item, i) => {
                return <div className="color-game-item" key={ i }>
                    <ReflexColor type={ item.number }></ReflexColor>
                    <div className="price">₹{ item.amount.toFixed(2) }</div>
                </div>;
            })
        }
    </div>;
}

export default Color;
