import dayjs from "dayjs";
import { useAppSelector } from "@/store";
import { Ellipsis } from "antd-mobile";

export default function Notification() {
    const { groupDetail } = useAppSelector(state => state.groupSetting);
    if (!groupDetail?.introduction) {
        return null;
    }
    return <div className="group-detail">
        <Ellipsis className="text" rows={ 5 }
                  expandText={ <span style={ { fontSize: 16, color: "#00A884", fontWeight: 500 } }>Read more</span> }
                  content={ groupDetail?.introduction! }/>
        <div className="sub-text">Created
            by { groupDetail?.notificationUserID }，{ dayjs(groupDetail?.createTime).format("YYYY/MM/DD HH:mm:ss") }</div>
    </div>;
}
