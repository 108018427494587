import { useCountDown } from "ahooks";
import { memo, useEffect, useRef } from "react";
import { addZero } from "@/utils/utils";

type CountTimesProps = {
    remindTime: string | number | Date;
    onEnd: () => void;
    text?: string;
}

function CountTime(props: CountTimesProps) {
    const { remindTime, onEnd, text = "Draw Time" } = props;
    const targetDate = useRef(remindTime);
    const [countdown, formattedRes] = useCountDown({
        targetDate: targetDate.current,
        interval: 500,
        onEnd: () => {
            onEnd?.();
        }
    });
    const { minutes, seconds, hours, days } = formattedRes;
    useEffect(() => {
        if (countdown <= 0) {
            onEnd?.();
        }
    }, []);
    const renderTime = () => {
        if (days > 0) {
            return `${days}days`;
        }
        return `${ addZero(hours) }:${ addZero(minutes) }:${ addZero(seconds) }`;
    };
    return <div className="my-count-time">
        <div className="text">{ text } </div>
        <span className="time">{
            renderTime()
        }</span>

    </div>;
}

export default memo(CountTime);
