import React, { memo } from "react";
import FloatModal from "@/components/FloatModal";
import { Image } from "antd-mobile";
import rulesIcon from "@/assets/svg/rules-modal-icon.svg";
import { useAppDispatch, useAppSelector } from "@/store";
import { setRuleModalVisible } from "@/store/slice/chat";

function RulesModal() {
    const noticeBarModalVisible = useAppSelector(state => state.chat.ruleModalVisible);
    const dispatch = useAppDispatch();
    return <FloatModal
        visible={ noticeBarModalVisible }
        title="Mirabel 💖"
        onClose={ () => {
            dispatch(setRuleModalVisible(false))
        } }
    >
        <div className="announcements">
            <div className="bell">
                <Image src={ rulesIcon } className="icon"></Image>
            </div>
            <div className="announcements-right">
                <div className="announcements-title">Chat Rules</div>
                <div className="announcements-inner">
                    <p>1. Do not spam, harass or offend other users. Also, avoid using capital letters!</p>
                    <p>2. Do not abuse others in the chat room, it will be banned.</p>
                    <p>3. No suspicious behavior that could be considered a potential scam.</p>
                    <p>4. Do not engage in any form of advertising/trading/selling/buying or providing services.</p>
                    <p>5. Don't use URL shorteners. Always submit the original link.</p>
                    <p>6. Use the chat rooms you need</p>
                    <p>7. Only when the VIP level reaches level 3 can the message sending function be unlocked</p>
                </div>
            </div>
        </div>

    </FloatModal>;
}

export default memo(RulesModal);
