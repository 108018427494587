import { MessageType } from "open-im-sdk";

export const GroupSystemMessageTypes = [
    MessageType.GroupCreated,
    // MessageType.GroupInfoUpdated,
    MessageType.MemberQuit,
    MessageType.GroupOwnerTransferred,
    MessageType.MemberKicked,
    MessageType.MemberInvited,
    MessageType.MemberEnter,
    MessageType.GroupDismissed,
    MessageType.GroupMemberMuted,
    MessageType.GroupMuted,
    MessageType.GroupCancelMuted,
    MessageType.GroupMemberCancelMuted,
    MessageType.GroupNameUpdated,
    MessageType.GroupAnnouncementUpdated
];

export const SystemMessageTypes = [
    MessageType.RevokeMessage,
    MessageType.FriendAdded,
    MessageType.BurnMessageChange,
    "localTime",
    ...GroupSystemMessageTypes,
];

export const canSearchMessageTypes = [
    MessageType.TextMessage,
    MessageType.AtTextMessage,
    MessageType.FileMessage,
    MessageType.QuoteMessage,
    MessageType.PictureMessage,
    MessageType.VideoMessage,
    MessageType.CardMessage,
    MessageType.LocationMessage,
    MessageType.VoiceMessage,
    MessageType.CustomMessage,
];
export const CustomMessageType = {
    MassMsg: 903,
};

export enum CustomType {
    CallingInvite = 200,
    CallingAccept = 201,
    CallingReject = 202,
    CallingCancel = 203,
    CallingHungup = 204,
}

