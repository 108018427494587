import PersonalCard from "./PersonalCard";
import { getVmSize } from "@/utils";
import { Ellipsis, Image } from "antd-mobile";
import totalWinsIcon from "@/assets/svg/totalWins.svg";
import totalBetsIcon from "@/assets/svg/totalBets.svg";
import totalWageredIcon from "@/assets/svg/totalWagered.svg";
import { useAppSelector } from "@/store";
import { formatMoney } from "@/utils/utils";

export default function Statistics() {
    const statistcs = useAppSelector(state => state.personal.statistics);

    const statisticsList = [
        {
            title: "total wins",
            icon: totalWinsIcon,
            align: "left",
            num: formatMoney(statistcs.totalWins)
        },
        {
            title: "total bets",
            icon: totalBetsIcon,
            align: "center",
            num: formatMoney(statistcs.totalBets)
        },
        {
            title: "total wagered",
            icon: totalWageredIcon,
            num: formatMoney(statistcs.totalWagered),
            align: "right"
        }
    ];
    return <PersonalCard title="Statistics" style={ { marginTop: getVmSize(24) } }>
        <div className="statistics-content">
            {
                statisticsList.map(item => {
                    return <div className="statistics-item" key={ item.title }
                                style={ { textAlign: item.align as any } }>
                        <div className="statistics-item-title" style={ { justifyContent: item.align as any } }>
                            <Image className="statistics-item-icon" src={ item.icon }></Image>
                            <span>{ item.title }</span>
                        </div>
                        <Ellipsis content={ '₹' + item.num } className="statistics-item-num"></Ellipsis>
                    </div>;
                })
            }
        </div>
    </PersonalCard>;
}
