// 判断字符串是不是数字类型
export const isNumber = (value: string | number) => {
    if (typeof value === "number") return true;
    const reg = /^\d+$/;
    return reg.test(value);
};

// 替换aliyuncs域名为
export const replaceOssImage = (sourceUrl: string = "", replaceUrl= "https://download.singamlottery.com") => {
    const regex = /https?:\/\/[^\/]+.aliyuncs\.com/;
    return sourceUrl.replace(regex, replaceUrl);
};
