import { useEffect, memo, useState } from "react";

const iconList = ["#DE3C3F", "#EC8305", "#046FEA"];
type ReflexDigit = {
    type: string;
    showType?: boolean
}
// quick 3d
function ReflexDigit(props: ReflexDigit) {
    const { type } = props;
    const [list, setList] = useState<{ icon: string, value: string}[]>([]);
    useEffect(() => {
        const numberList = type.split("");
        const digitList = numberList.map((item, index) => {
            return {
                icon: iconList[index],
                value: numberList[index]
            };
        });
        setList(digitList);
    }, []);
    return (
        <div className="reflex-container">
            <div className="digit">
                {
                    list.map((item, index) => {
                        return <div key={index} className="digit-item" style={ {
                            background:item.icon
                        } }>{ item.value } </div>;
                    })
                }
            </div>
        </div>
    );
}

export default memo(ReflexDigit);
