import { useParams, useSearchParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import {
    ConversationType,
    fetchHistoryList,
    resetChat,
    setChatInfo,
    setGroupOwnerList,
    updateGroupInfo,
    updateSingleInfo
} from "@/store/slice/chat";
import store2 from "store2";
import { ImContext } from "@/openIm";
import { reset } from "@/store/slice/personal";

// 初始化chat信息
function useChatInfo() {
    const chatStore = useAppSelector(state => state.chat);
    const { openIm } = useContext(ImContext);
    const { list, loading } = chatStore;
    const params = useParams();
    const { conversationID = "" } = params;
    const [searchParams] = useSearchParams();
    const groupID = searchParams.get("groupID");
    const userID = searchParams.get("userID");
    const dispatch = useAppDispatch();
    useEffect(() => {
        const conversationType = groupID ? ConversationType.Group : ConversationType.Single;
        // 更新chat的信息
        dispatch(setChatInfo({
            groupID,
            userID,
            conversationID,
            conversationType,
            currentUserID: store2.get("userID")
        }));
        // 更新chat的聊天历史信息
        dispatch(fetchHistoryList(conversationID));
        // 获取群管理员列表
        openIm.getGroupMemberOwnerAndAdmin(groupID!).then(res => {
            dispatch(setGroupOwnerList(res));
        });
        // 获取群组的详情信息
        if (conversationType === ConversationType.Group && groupID) {
            openIm.getSpecifiedGroupsInfo(groupID).then(groupInfo => {
                dispatch(updateGroupInfo(groupInfo));
            });
        }
        // 更新个人详情
        if (conversationType === ConversationType.Single && userID) {
            openIm.getUsersInfo(userID).then(singleInfo => {
                dispatch(updateSingleInfo(singleInfo.publicInfo));
            });
        }
        return () => {
            // 退出聊天窗口，需要重置数据
            dispatch(resetChat());
            dispatch(reset());
        };
    }, []);
    return {
        list,
        loading
    };
}

export default useChatInfo;
