import { Image, NoticeBar } from "antd-mobile";
import React from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import { setNoticeBarModalVisible } from "@/store/slice/chat";
import { setTwiceVisible } from "@/store/slice/common";

function NoticeBarCom() {
    const groupInfo = useAppSelector(state => state.chat.groupInfo);
    const dispatch = useAppDispatch();
    const noticeBarVisible = useAppSelector(state => state.chat.noticeBarVisible);
    if (!groupInfo || !noticeBarVisible) {
        return null;
    }
    const handleShowModal = () => {
        dispatch(setNoticeBarModalVisible(true));
    };
    return <div className="noticeBar-conatiner">
        <NoticeBar
            className="noticeBar"
            icon={ <Image className="icon_notice" src={ require("@/assets/svg/notice.svg").default }></Image> }
            content={ <div onClick={ handleShowModal }>{ groupInfo.notification }</div> }
            extra={
                <>
                    <img className="icon_right"
                         onClick={ () => {
                             dispatch(setTwiceVisible(true));
                         } }
                         src={ require("@/assets/svg/close-modal.svg").default } alt=""/>
                    <div className="noticeLieaner"></div>
                </>
            }
        ></NoticeBar>
    </div>;
}

export default NoticeBarCom;
